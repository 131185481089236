.sidebar {
  display: none;

  button.sidebar-expand,
  button.sidebar-collapse {
    position: absolute;
    margin-top: 15px;
    border-left: 0;
  }

  button.sidebar-collapse {
    z-index: 2;
    right: -39px;
  }

  @include mediaquery-medium {
    display: block;
    position: absolute;
    z-index: 10;
    width: 0;
    box-shadow: 0 1px 2px rgba($black100, 0.2);
    align-self: stretch;

    &.expanded {
      width: 200px;
      min-height: 100%;
      .sidebar-wrapper {
        overflow: visible;
      }
    }
  }

  .sidebar-wrapper {
    overflow: hidden;
  }

  @include mediaquery-xlarge {
    width: 200px;
    align-self: stretch;
    position: initial;
    box-shadow: none;
    .sidebar-wrapper {
      overflow: visible;
    }
    //hide buttons when the breakpoint is large
    button.sidebar-expand,
    button.sidebar-collapse {
      display: none;
    }
  }

  align-self: stretch;
  position: relative;
  background: $white;
  border-right: 1px solid $black10;
  height: inherit;

  outline: 0;

  .sidebar-wrapper {
    width: 100%;
    height: inherit;

    //All filter styles for sidebar component body
    .filters {
      width: 199px; // Substract 1px because of the border-right
      height: inherit;
      padding: 20px 15px;
    }

    .observer {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }

    .filter-group {
      position: relative; // Necessary for VariableViewFilters to display correctly
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
        border-bottom: 0;
      }

      .review-container {
        display: flex;
        .filter-name {
          font-size: 1.2rem;
          align-self: flex-end;
        }
        .dmp-switch {
          margin-left: auto;
          width: 21px;
        }
      }

      .title {
        font-size: 1.2rem;
        color: $black80;
        font-weight: 600;
        padding-bottom: 10px;
      }

      .switch {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex: 1;
        font-size: 1.2rem;
        color: $black80;
        font-weight: 600;
        padding-bottom: 10px;

        .switch-filter {
          display: flex;
          a {
            margin-left: 5px;
          }
        }

        .dmp-switch {
          label {
            span {
              display: none;
            }
          }
        }

        svg {
          width: 15px;
          path {
            fill: $black20;
          }
        }
        &:hover {
          svg path {
            fill: $black80;
          }
        }
      }

      .#{$dmp-checkbox} {
        margin-bottom: 5px;

        label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span .facet-suggestion-parties {
            -webkit-line-clamp: 4;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }
      }

      // WIP: for now restrict height in case there are a lot of custom workflows/steps
      // to be replaced by a multiselect component
      &.custom-wf,
      &.scrollbar-tags {
        .#{$dmp-checkbox} {
          label {
            white-space: break-spaces;
            word-wrap: break-word;
          }
        }
        .options {
          padding: 5px 10px;
          border: 1px solid $black10;
          max-height: 170px;
          overflow-y: scroll;
        }
      }
    }

    .facet-search {
      position: relative;
      margin-bottom: 10px;

      input {
        padding: 2px 5px 2px 25px;
      }

      .search-icon {
        position: absolute;
        z-index: 1;
        pointer-events: none;
        left: 7px;
        top: 7px;
        width: 14px;

        path {
          fill: $black40;
        }
      }

      .dmp-loader {
        position: absolute;
        top: 8px;
        left: 5px;
      }

      &.focused .search-icon path {
        fill: $black100;
      }

      .facet-suggestions {
        padding-top: 10px;

        .suggestion-email {
          display: block;
        }
      }

      .no-results {
        margin-top: 5px;
        font-size: 1.3rem;
        color: $black40;
      }
    }

    .grade-filter {
      .dmp-checkbox {
        margin-bottom: 0px;
        label {
          font-size: 1.2rem;
          flex: 1;
          padding-right: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .lens-filter,
    .variable-filter {
      cursor: pointer;
      margin-bottom: 6px;

      .filter-display-label {
        font-size: 1.1rem;
        flex: 1;
        padding-right: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .filters-applied {
        font-size: 1.1rem;
        flex: 1;
        padding-right: 10px;
        text-overflow: wrap;
        overflow: hidden;
        color: $outlaw;

        .filter-label {
          margin-top: 5px;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .filter-name {
        display: flex;
        align-items: center;
        .title {
          padding-bottom: 0px !important;
        }

        .lens-type {
          width: 15px;
          path {
            fill: $gray-1 !important;
          }
        }

        div:first-of-type {
          font-size: 1.2rem;
          flex: 1;
          padding-right: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        svg {
          width: 15px;
          path {
            fill: $black20;
          }
        }
        &:hover {
          svg path {
            fill: $black80;
          }
        }
        &.active {
          font-weight: 600;
          svg path {
            fill: $outlaw;
          }
          &:hover {
            svg path {
              fill: darken($outlaw, 5%);
            }
          }
        }
      }
      .existing {
        display: flex;
        align-items: center;
        margin-top: -1px;

        svg {
          width: 15px;
          margin-right: 5px;
        }

        .filter-display {
          font-size: 1.2rem;
          color: $outlaw;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .dates {
      .title {
        font-size: 1.2rem;
        color: $black80;
        font-weight: 600;
        padding-bottom: 10px;
      }
    }

    .saved-filters {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .empty {
        color: $black40;
        font-size: 1.4rem;
      }
    }
  }

  .filters-actions {
    .btn {
      margin-right: 12px;
    }
  }

  .dmp-combobox.dmp-combobox-size-small {
    .rw-widget-picker,
    .rw-widget-container {
      box-shadow: inset 0px -2px 0px rgba(212, 219, 228, 0.5);
    }
  }
}
