/*
  React Virtualized
*/

.ReactVirtualized__Grid {
  overflow: visible !important;
  z-index: 10;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}
