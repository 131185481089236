//typography styles used both in static and app
body {
  @extend .font_sans;
  // text-rendering: optimizelegibility;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
}
small {
  font-size: 1.2rem;
  line-height: 1.5;
  color: $gray-2;
}
table {
  font-size: 1.6rem;
  line-height: 1.1;
  color: $black60;
}
th {
  font-size: 1.2rem;
  line-height: 1.2;
  color: $black60;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    font-weight: 400;
    //padding-bottom:10px;
  }
}

.deprecated {
  color: rgba($alert, 0.7);
}

.invalid {
  @extend .font_size_xs;
  color: $alert;
}

.text-danger {
  color: $color-red;
}

.text-success {
  color: $done;
}

.text-disabled {
  color: #8795a5; // $gray-2
}

/*
  Adjustable links until we figure out how we want to handle links in content vs links in UI.
  Enabling underlined links everywhee could cause a lot undesired UX changes.
*/
a.text-link {
  text-decoration: underline;
}

.text-content-highlight {
  text-wrap: wrap;
  font-size: 1.1rem;
  color: $gray-1;
  padding-top: 4px;
  font-weight: 500;

  em {
    color: $gray-0;
    font-weight: 600;
    font-style: normal;
    background: lighten($discuss, 30%);
    border-radius: 2px;
    display: inline;
  }
}
