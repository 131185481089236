.modal-content {
  // parties modal styling
  .using-outlaw {
    .btn-group {
      margin-left: 20px;
    }
  }
}
.deal-section {
  &.parties {
    padding-bottom: 16px;

    .section-title {
      border-bottom: 1px solid $black10;
    }
  }

  .party-type {
    border: 1px solid $black10;
    // margin-left:16px;
    // margin-right: 16px;
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;

    @include mediaquery-medium {
      border: none;
      width: calc(50% - 32px); //hack until i can work out why first-child isnt working
      float: left;
      margin-top: 0;
      margin-left: 24px;
      margin-right: 0;
    }

    .party-header {
      position: relative;
      padding-bottom: 12px;
      padding-top: 16px;
      padding-left: 16px;
      @include mediaquery-medium {
        padding-left: 0;
        // padding-top: 0;
      }

      .title {
        font-weight: 500;
        font-size: 1.8rem;
        color: $black60;
        display: inline;
        img {
          margin: -5px 5px 0 0;
          // opacity: .2;
          // width: 34px;
          // height: 34px;
        }
      }
      .section-item-actions {
        top: 10px;
        right: 16px;
        @include mediaquery-medium {
          right: 0px;
        }
        &.hideable {
          display: block;
        }
      }
    }
  }
}

// .disclaimer {
//   .modal-body {
//     padding: 24px;
//     text-align: center;
//   }
//   p {
//     &:not(:last-child) { margin-bottom: 16px; }
//     font-size: 1.5rem;
//     // line-height: 22px;

//     //force better line-break for ToS link para
//     @include mediaquery-medium {
//       &:last-child { margin: auto 100px; }
//     }

//   }
//   a {
//     color: $black80;
//     font-weight: bold;
//     &:hover { text-decoration: underline; }
//   }
//   .glyphicon {
//     margin: auto 6px;
//     color: $alert;
//   }
//   //this class is no longer used, but was applied to the disclaimer about ToS when it was next to buttons
//   .terms {
//     color: $black40;
//     float: left;
//     max-width: 50%;
//     text-align: left;
//     font-size: 1.2rem;
//     a { color: $black40; }
//   }
// }

.signature-pad {
  border-radius: 3px;
  padding: 0;
  text-align: center;
  .modal-header {
    padding: 12px;
  }

  //footer overrides to provide space for signing and ensure that eSignature edisclaimer is properly visible
  .modal-footer {
    display: flex;
    align-items: center;
    margin-top: 30px !important;

    .esigning {
      color: $black60;
      a {
        color: $black60;
        text-decoration: underline;
      }
    }
    .spacer {
      flex: 1;
    }

    .dmp-loader.sig-pad-loader {
      position: relative;
      left: auto;
      bottom: auto;
      margin-right: 10px;
    }
  }

  .sig-type {
    width: 100%;
    text-align: center;
    .btn-group {
      margin: 20px;
    }
  }
  //
  .bg {
    // position: absolute;
    height: 150px;
    margin: auto 24px;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    //hack to draw bg line so that it appears underneath the drawn signature
    // &.draw { background: linear-gradient(0deg, transparent 29px, $discuss 30px, transparent 31px); }
    background: linear-gradient(0deg, transparent 29px, $discuss 30px, transparent 31px);

    .sign-here {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -4px;
      font-weight: 400;
      font-size: 1.3rem;
      color: $black20;
    }
  }
  //
  .canvas-draw {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 160px;
    width: 540px;

    &.Type {
      display: none;
    }
  }
  &.saving .canvas-draw {
    pointer-events: none;
  }
  .sig-text {
    &.Draw {
      display: none;
    }

    position: absolute;
    top: 12px;
    left: 24px;
    right: 24px;
    z-index: 1000;

    input {
      color: black;
      text-align: center;
      @extend .font_script;
      background: none;
    }

    bottom: 45px;
    input {
      position: absolute;
      height: 120px;
      width: 100%;
      font-size: 4.5rem !important;
      @include mediaquery-medium {
        font-size: 6rem !important;
      }
      border: none;
      box-shadow: none;
    }
  }
}

//minor tooltip overrides because the eSignature disclaimer is a bit on the long side
#tip-siggy-esigning .tooltip-inner {
  max-width: 300px;
  text-align: left;
  padding: 10px 15px;
}
