//invite modal
.popover-send-deal {
  min-width: 100%;

  // Temporary form adjustments since this is not yet using @dmp/Popover (ref issue)
  form {
    .form-control,
    .share-type {
      margin-bottom: 6px;
    }
  }

  // SendDeal popovers are now finally properly contained within the Users panel! Woohoo!
  // So on desktop make them 2px narrower than the panel to account for borders and avoid tiny horizontal scroll
  // NB: Explicit pixel-based width (not 100%) is still necessary on desktop,
  // because SendDeal is used in other places too (e.g., in ABC and DealHeader)
  // CHANGE: This is still too large, removing 20px, this should be handled better anyway
  @include mediaquery-medium {
    min-width: $panel-desktop-width - 30;
  }

  // Copied from DealUserView; overriding default to align with smaller title
  .close-popover {
    right: 17px;
    top: 17px;
  }

  &.bottom {
    margin-bottom: 100px;
  }

  .share-type {
    display: flex;
    width: 100%;
    button {
      flex: 1;
    }
  }

  .custom-message {
    height: 140px;
  }

  .url {
    &.copied {
      border: 1px solid $outlaw;
    }
  }
  span.copied {
    position: absolute;
    margin-top: -24px;
    right: 30px;
    background: $outlaw;
    color: white;
    font-size: 1.1rem;
    padding: 1px 5px;
  }

  .send-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .info {
      flex: 3;
      text-align: left;

      .error {
        color: $alert;
      }
    }
    .action {
      flex: 1;
      text-align: right;
      display: flex;
      button {
        margin-bottom: 0;
      } //override popover style
    }
  }
}
