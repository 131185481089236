.contract-uploader,
.batch-signer,
.total-batch,
.deal-version-uploader,
.version-committer,
.pdf-compiler {
  .form-group {
    margin-bottom: 0;
  }

  .dmp-alert {
    margin-bottom: 0;
  }

  .explanation {
    @extend .font_size_xs;
    color: $black60;
    margin: 10px 0;
  }

  .existing-version {
    display: flex;
    align-items: center;
  }

  .deal-status {
    max-width: calc(100% - 125px);
  }

  .upload-status {
    display: flex;
    padding: 30px 0px 0px 20px;
    flex: 1;
    font-size: 1.3rem;
    font-weight: 600;

    &.error {
      color: $alert;
    }
    &.ready {
      color: $done;
    }
    &.no-changes {
      color: $black40;
    }
  }

  .dmp-loader {
    position: absolute;
    bottom: 20px;
    left: 18px;
  }

  .saving {
    padding: 20px;
    .progress {
      margin-bottom: 10px;
    }
    .details {
      display: flex;
      flex-direction: row;
      .step {
        font-weight: 600;
        margin-right: 10px;
      }
    }
    &.error {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .date .radio {
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 5px;
    input {
      margin-top: 0;
    }
    label {
      display: flex;
      align-items: center;
      .dmp-daytime {
        margin-left: 10px;
      }
    }
  }

  .signing-results {
    padding: 0 20px;
    display: flex;
    .results-label {
      flex: 1;
      font-weight: 600;
    }
    .results-details {
      flex: 2;
      .result-count {
        font-weight: 700;
      }
      .error-summary {
        color: $pending;
        margin: 15px 0;
      }
    }
  }

  .variable {
    .error {
      color: $alert;
      margin: 5px 0;
    }
  }
}

.file-up,
.ai-up {
  border: 1px solid $black10;
  border-radius: 3px;
  padding: 10px;
  width: 100%;

  .hit-area {
    position: relative;
    border: 1px dashed $black10;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
      margin: 20px;
      overflow: inherit;
    }

    .instructions {
      display: flex;
      flex-direction: column;

      //override standard styles
      h4 {
        width: 100%;
        margin: 0;
        overflow-wrap: anywhere;
      }
      small {
        padding: 0;
      }
    }

    .clear {
      position: absolute;
      padding: 0 0 0 1px !important;
      top: -8px;
      right: -8px;
      border: 1px solid $black10;
      padding: 3px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      line-height: 0;
      text-align: center;
    }

    &:hover {
      cursor: pointer;
    }
  }
  .error {
    color: $alert;
  }
}

.ai-up {
  .hit-area {
    padding: 2px 5px;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
  h4 {
    font-size: 1.4rem;
  }
}
