/*
  PAGE : Doc Compare
*/

#page-doc-compare {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  min-height: 400px;

  .dmp-card {
    width: 500px;
  }

  .spacer {
    width: 20px;
  }

  .file-up {
    border: 1px solid $black10;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
    margin: 2px;

    .hit-area {
      position: relative;
      border: 1px dashed $black10;
      border-radius: 3px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px;

      > svg {
        margin: 20px;
        overflow: inherit;
      }

      .instructions {
        display: flex;
        flex-direction: column;

        //override standard styles
        h4 {
          font-size: 1.4rem;
          width: 100%;
          margin: 0;
          overflow-wrap: anywhere;
        }
      }

      .clear {
        position: absolute;
        padding: 0 0 0 1px !important;
        top: -8px;
        right: -8px;
        border: 1px solid $black10;
        padding: 3px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        line-height: 0;
        text-align: center;
      }

      &:hover {
        cursor: pointer;
      }
    }
    .error {
      color: $alert;
    }
  }

  .file-download {
    margin-top: 16px;
    font-weight: bolder;
    display: none;
  }

  .error-message {
    color: red;
    max-height: 200px;
    overflow: auto;
  }
}
