/*
  Bootstrap Outlaw
  Bootstrap scss customization.
*/

//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$gray-base: $gray-dark;
$gray-darker: $gray-0;
//$gray-dark:              $gray-dark;
$gray: $gray-2;
$gray-light: $gray-3;
$gray-lighter: $gray-3;
$brand-primary: $outlaw;
$brand-info: $discuss;
$brand-danger: $color-red;
$brand-warning: $color-yellow;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: $white;
//** Global text color on `<body>`.
$text-color: $gray-0;

//** Global textual link color.
$link-color: $gray-0;
//** Link hover color set via `darken()` function.
$link-hover-color: $gray-0;
//** Link hover decoration.
$link-hover-decoration: underline;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 9px;
$padding-base-horizontal: 12px;

//$padding-large-vertical:    10px !default;
//$padding-large-horizontal:  16px !default;

$padding-small-vertical: 5px;
$padding-small-horizontal: 8px;

//$padding-xs-vertical:       1px !default;
//$padding-xs-horizontal:     5px !default;

//$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
//$line-height-small:         1.5 !default;

$border-radius-base: 3px;

//** Global color for active items (e.g., navs or dropdowns).
//$component-active-color:    #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
//$component-active-bg:       $brand-primary !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
//$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
//$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif;

$font-size-base: 14px;
//$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px

//$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
//$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
//$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
//$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
//$font-size-h5:            $font-size-base !default;
//$font-size-h6:            ceil(($font-size-base * .85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
//$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
//$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
//$headings-font-family:    inherit !default;
//$headings-font-weight:    500 !default;
//$headings-line-height:    1.1 !default;
//$headings-color:          inherit !default;

//== Buttons
//
$btn-font-weight: 500;
$btn-default-color: $gray-0; // $gray-darker
$btn-default-border: $gray-3;

$btn-primary-border: transparent;

$btn-danger-color: $white;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.
$state-success-text: $color-dark-green;
$state-success-bg: #dff7ee;
$state-success-border: #2dcb8d;

$state-info-text: $color-dark-blue;
$state-info-bg: #e8f1fe;
$state-info-border: #67a0f8;

$state-warning-text: $color-dark-yellow;
$state-warning-bg: #fef9ef;
$state-warning-border: #f6b36b;

$state-danger-text: $color-dark-red;
$state-danger-bg: #fce8e7;
$state-danger-border: #ee6662;

//== Forms
//
$input-bg: $white;
$input-color: $gray-0;
$input-border: $gray-3;
$input-border-focus: $gray-2;
$input-color-placeholder: $gray-2;

$form-group-margin-bottom: 10px;

//== Dropdowns
//
//** Background for the dropdown menu.
$dropdown-bg: #fff;
//** Dropdown menu `border-color`.
$dropdown-border: $gray-3; // $gray-light
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: $gray-3; // $gray-light
//** Divider color for between dropdown items.
$dropdown-divider-bg: $gray-3; // $gray-light

//** Dropdown link text color.
$dropdown-link-color: $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color: $gray-dark;
//** Hover background for dropdown links.
$dropdown-link-hover-bg: $gray-4;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $gray-0;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: transparent;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-2;

//** Text color for headers within dropdown menus.
//$dropdown-header-color:          $gray-light !default;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
//$dropdown-caret-color:           #000 !default;

//== alert
//
$alert-padding: 10px 15px;

//== Popovers
//

$popover-bg: #fff;
$popover-max-width: 300px;
$popover-border-color: #d4dbe4; // $gray-light
$popover-fallback-border-color: #d4dbe4; // $gray-light
$popover-title-bg: #d4dbe4; // $gray-light
$popover-arrow-width: 6px;
//$popover-arrow-color:                 $popover-bg !default;
//$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
//$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) !default;
//$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) !default;
