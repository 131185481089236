/*
  PAGE : CONNECT
*/

/*
  Modals
*/
.modal-automation-tester {
  .modal-dialog {
    width: 780px;
    max-width: 90%;

    .modal-body {
      pre {
        max-height: 600px;
      }
    }
  }
}

/*
  Team, template and service setting block
*/
.service-selector {
  .setting-title {
    font-weight: normal !important;
    font-size: 1.4rem;
    color: $black80;
    display: flow-root;
  }

  .form-group {
    padding-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .setting-content {
    margin-top: -18px;
    @media screen and (max-width: 435px) {
      margin-top: 10px;
    }
    .connect-team-selector {
      margin-left: 40%;
      width: 118px;
      .btn-block {
        width: 100%;
      }
      .dmp-dd-menu {
        position: absolute;
        max-width: 100%;
        min-width: 100%;
        // margin-left: 10px;
      }
    }

    .connect-template-selector {
      margin-left: 40%;
      width: 100px;
      .dmp-dd-menu {
        min-width: 100%;
        max-width: 100%;
      }
    }

    .connect-selector {
      margin-left: 40%;
      width: 100%;
      .dropdown-toggle {
        width: 100%;
      }
      .dmp-dd-menu {
        position: absolute;
        z-index: 1000;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}
