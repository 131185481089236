/*
  Preloader styling
*/

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

#preloader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  // Move everything up a bit
  &:not(.hide-logo) {
    padding-bottom: 100px;
  }

  .preloader-logo {
    display: block;
    width: 160px;
    height: 40px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 30px;

    opacity: 1;
    transform: translateY(0);

    &.animate {
      opacity: 0;
      transform: translateY(30px);
      animation: slideInFromBottom 0.5s ease 0.4s;
      animation-fill-mode: forwards;
    }
  }
}
