/*
  DMP COMPONENT: ELLIPSIS
*/

$dmp-ellipsis: '#{$dmp}-ellipsis';

.#{$dmp-ellipsis} {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 1px;
  max-width: 100%;

  &.wrap {
    white-space: normal;
  }

  &-multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

span.#{$dmp-ellipsis} {
  display: inline-block;
}
