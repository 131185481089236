//mixins

@mixin clearfix {
  // micro clearfix hack
  zoom: 1;
  clear: both;
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin border-box {
  // border-box mixin
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@mixin mediaquery-xsmall {
  @media only screen and (min-width: $breakpoint-mobile-small) {
    @content;
  }
}
//media query
@mixin mediaquery-small {
  @media only screen and (min-width: $breakpoint-mobile-large) {
    @content;
  }
}
@mixin mediaquery-medium {
  @media only screen and (min-width: $breakpoint-tablet) {
    @content;
  }
}
@mixin mediaquery-large {
  @media only screen and (min-width: $breakpoint-desktop) {
    @content;
  }
}
@mixin mediaquery-xlarge {
  @media only screen and (min-width: $breakpoint-desktop-large) {
    @content;
  }
}

//animation
@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';
  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};
    @if $i < $max {
      $animations: #{$animations + ', '};
    }
  }
  animation: $animations;
}
@mixin keyframes($animationName) {
  @keyframes #{$animationName} {
    @content;
  }
}
@include keyframes(fade_in) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@include keyframes(slide_in) {
  0% {
    top: -100px;
  }
  100% {
    top: 0px;
  }
}
//----------------------------------------------------------------
//As with animations, transitions also make your code quite bloated which can hurt the readability.
//But this is also solved by using a mixin for it.
@mixin transition($args...) {
  transition: $args;
}

//----------------------------------------------------------------
@mixin fade($type) {
  @if $type == 'hide' {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s;
  } @else if $type == 'show' {
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
  }
}
/*to use
.foo .bar {
  @include fade(hide);
}
.foo:hover .bar {
  @include fade(show);
}*/

//----------------------------------------------------------------
@mixin center($position) {
  position: absolute;
  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  } @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
/*to use
.foo { @include center(both); }
.foo-parent { position: relative; }*/

@mixin font-sizing($size) {
  line-height: 1.5; //consistent line-height
  // 13px mobile : 15px desktop
  @if $size == 'x-small' {
    font-size: 1.1rem;
    @include mediaquery-medium {
      font-size: 1.3rem;
    }
  }
  @if $size == 'small' {
    font-size: 1.3rem;
    @include mediaquery-medium {
      font-size: 1.5rem;
    }
  }
  // 15px mobile : 17px desktop
  @else if $size == 'medium' {
    font-size: 1.5rem;
    @include mediaquery-medium {
      font-size: 1.7rem;
    }
  }
  // 22px mobile : 24px desktop
  @else if $size == 'large' {
    font-size: 1.8rem;
    @include mediaquery-medium {
      font-size: 2.2rem;
    }
  }
  // 22px mobile : 24px desktop
  @else if $size == 'x-large' {
    font-size: 2.2rem;
    @include mediaquery-medium {
      font-size: 2.5rem;
    }
  }
  // 25px mobile : 27px desktop
  @else if $size == 'xx-large' {
    font-size: 2.5rem;
    @include mediaquery-medium {
      font-size: 2.7rem;
    }
  }
}

//mixin for cross-browser input placeholder styling!
//thanks again internet! http://stackoverflow.com/questions/23728726/css-less-placeholder-mixin
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin labelize($color) {
  background-color: $color;
  .arrow {
    border-right-color: $color;
    &.right {
      border-left-color: $color;
    }
  }
}

@mixin element($color) {
  color: $color;
  background: rgba($color, 0.1);
  border-bottom: 1px dashed $color;
  &:hover {
    background-color: rgba($color, 0.25);
  }
}

@mixin timelineEvent($bgcolor, $bordercolor) {
  color: $gray-1;
  border: 1px solid $bordercolor;
  border-radius: 0 5px 5px 0;
  background: $bgcolor;

  .tl-item-type {
    color: $bordercolor;
  }
}

@mixin wide-dropdown {
  //override bootstrap shit
  width: 100%;
  button {
    position: unset;
    text-align: left;
    width: 100%;
    .caret {
      float: right;
      margin-top: 8px;
    }
  }
}

@mixin initialCircle($bg) {
  cursor: default;
  background-color: $bg;
  border-radius: 50%;
  margin-right: 6px;
  font-size: 8px;
  color: $white;
  line-height: 23px;
  width: 22px;
  height: 22px;
  text-align: center;
  font-weight: 700;
  position: relative;
}

@mixin pageBreak {
  position: relative;
  .page-break,
  .header-footer-break {
    position: absolute;
    height: 1px;
    max-width: calc(100vw + 80px);
    border-top: 1px dashed $gray-3;
    @include mediaquery-small {
      max-width: 100vw;
    }

    &.header-break {
      bottom: -8px;
    }

    &.footer-break {
      top: -8px;
    }

    .page-break-name,
    .header-footer-break-name {
      color: $gray-2;
      position: absolute;
      top: -9px;
      background: $white;
      @extend .font_sans;
      font-size: 10px;
      padding: 0 3px;
    }
  }

  .page-break {
    top: -5px;
    .page-break-name {
      right: 10px;
      font-weight: 400;
    }
  }

  .header-footer-break {
    .header-footer-break-name {
      right: 10px;
      font-weight: 400;
    }
  }
}

//overrides necessary to get DraftJS placeholders to be properly centered
@mixin centerPH {
  .DraftEditor-root {
    position: relative;
    .public-DraftEditorPlaceholder-root {
      width: 100%;
      left: 0;
      position: absolute;
      z-index: -1;
      .public-DraftEditorPlaceholder-inner {
        position: relative;
        text-align: center;
      }
    }
  }
}

@mixin rightPH {
  .DraftEditor-root {
    position: relative;
    .public-DraftEditorPlaceholder-root {
      width: 100%;
      left: 0;
      position: absolute;
      z-index: -1;
      .public-DraftEditorPlaceholder-inner {
        position: relative;
        text-align: right;
      }
    }
  }
}

.colorLabel {
  &.deal-status-agreed,
  &.deal-status-complete,
  &.deal-status-none {
    // display: none;
    opacity: 0.3;
    @include labelize($black20);
  }
  &.deal-status-discuss {
    @include labelize($discuss);
  }
  &.deal-status-pending {
    @include labelize($discuss);
  }
  &.deal-status-review {
    @include labelize($discuss);
  }
  &.deal-status-new {
    @include labelize($pending);
  }
  &.deal-status-todo {
    @include labelize($discuss);
  }
  &.deal-status-proposed {
    @include labelize($black20);
  }
  &.deal-status-signing {
    @include labelize($black20);
  }
  &.conditions {
    @include labelize($outlaw);
  }

  &.deal-status-alert {
    @include labelize($alert);
    cursor: default;
  }

  &.deal-status-proposed,
  &.deal-status-review {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
    @include labelize($black20);
  }

  .arrow {
    position: absolute;
    left: -8px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 11px solid transparent;
    &.right {
      left: auto;
      right: -7px;
      border-right: none;
      border-left: 8px solid transparent;
    }
  }
  height: 22px;
  font-size: 11px;
  @extend .font_sans;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
  min-width: 24px;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  padding-left: 5px;
  padding-right: 6px;
  z-index: 9;
  position: absolute;
}

.dealTag {
  font-size: 0.9rem;
  color: $black40;
  font-weight: 500;
  border: 1px solid $black10;
  border-radius: 2px;
  line-height: 1.2;
  padding: 0px 3px 0 11px;
  text-transform: capitalize;
  background: $white;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 5px;
    height: 5px;
    background: $black20;
    clip-path: polygon(0 55%, 55% 0, 100% 0, 100% 50%, 50% 100%);
    -webkit-clip-path: polygon(0 55%, 55% 0, 100% 0, 100% 50%, 50% 100%);
  }
}

// helper classes ---------------------------------------------

//padding top
.sm-padTop-min {
  padding-top: 10px;
}
.md-padTop-min {
  @include mediaquery-medium {
    padding-top: 20px;
  }
}
.sm-padTop-sml {
  padding-top: 40px;
}
.md-padTop-sml {
  @include mediaquery-medium {
    padding-top: 80px;
  }
}
.sm-padTop-med {
  padding-top: 60px;
}
.md-padTop-med {
  @include mediaquery-medium {
    padding-top: 100px;
  }
}
.sm-padTop-lge {
  padding-top: 80px;
}
.md-padTop-lge {
  @include mediaquery-medium {
    padding-top: 120px;
  }
}
//padding bottom
.sm-padBot-min {
  padding-bottom: 10px;
}
.md-padBot-min {
  @include mediaquery-medium {
    padding-bottom: 20px;
  }
}
.sm-padBot-sml {
  padding-bottom: 40px;
}
.md-padBot-sml {
  @include mediaquery-medium {
    padding-bottom: 80px;
  }
}
.sm-padBot-med {
  padding-bottom: 60px;
}
.md-padBot-med {
  @include mediaquery-medium {
    padding-bottom: 100px;
  }
}
.sm-padBot-lge {
  padding-bottom: 80px;
}
.md-padBot-lge {
  @include mediaquery-medium {
    padding-bottom: 120px;
  }
}

.rightAlign {
  text-align: right;
}

.clearLink {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.2rem;
  text-decoration: underline;
  color: $black100;
  cursor: pointer;
}
