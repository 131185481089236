/*
  DMP COMPONENT: ALERT
*/

$dmp-alert: '#{$dmp}-alert';

.#{$dmp-alert} {
  display: flex;
  font-size: 1.3rem;
  @extend .font_sans;

  &-title {
    flex: 1;
    margin-bottom: 6px;
  }

  &-content {
    flex: 1;
  }

  a {
    color: inherit;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }

  &-icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    path {
      fill: $black40;
    }
    margin-right: 10px;
  }

  /*
    Sizes
  */
  &-small,
  &-xsmall {
    font-size: 1.2rem;
    font-weight: 400;

    h4 {
      font-size: inherit;
      line-height: 2rem;
      font-weight: 700;
    }
  }

  &-xsmall {
    padding: 8px 10px;
    margin-bottom: 10px;
    line-height: 15px;
  }

  /*
    Inline
    Use in lists, menus, etc.
    This prop disable: all margins and border-radius.
  */
  &-inline {
    border-radius: 0;
    margin: 0;
  }

  /*
    Centered
    Centering text for the hole alert
  */
  &-centered {
    text-align: center;
  }

  /*
    Styles
  */
  &-dark {
    background-color: $primary-dark;
    color: $black20;

    h4 {
      color: $white;
    }

    a {
      color: inherit;
      &:hover {
        color: $white;
      }
    }
  }

  &-light {
    background-color: $white;
    border-color: transparent;
  }
}
