/*
  PAGE : STORAGE
*/

#page-storage {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  min-height: 400px;

  #download-btn {
    width: 280px;
    text-align: left;

    .dmp-ellipsis {
      line-height: 0.5em;
    }

    small {
      color: inherit;
      opacity: 0.75;
    }
  }
}
