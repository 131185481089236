@import '~react-toastify/dist/ReactToastify.css';

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #{$color-dark-blue};
  --toastify-color-success: #{$color-dark-green};
  --toastify-color-warning: #{$color-dark-yellow};
  --toastify-color-error: #{$color-dark-red};
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #2b3542;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: #{$color-purple};
  // Used when no type is provided
  --toastify-color-progress-dark: #{$color-purple};
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  // used to control the opacity of the progress trail
  --toastify-color-progress-bgo: 0.2;
}

@keyframes Toastify__fadeIn {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes Toastify__fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.Toastify__fade-enter {
  animation: Toastify__fadeIn 0.25s ease-in both;
}

.Toastify__fade-exit {
  animation: Toastify__fadeOut 0.5s ease-in both;
}

.Toastify__toast-body {
  font-size: 12px;
  padding: 6px 6px 11px;
  .Toastify__toast-icon {
    width: 30px;
  }
}
