/*
  DMP COMPONENT: LENS
*/

$dmp-lens: '#{$dmp}-lens';

.#{$dmp-lens} {

  &-section {
    border: 1px $discuss dashed;
    background: transparent;
    opacity: 1;
  }

  &-continues-after {
    border-bottom: none;
  }
  &-continues-before {
    border-top: none;
  }

  &-table {
    border: 1px $color-yellow solid;
    background: rgba($color-yellow, .2);
  }

  &-preamble {
    border: 1px $discuss solid;
    background: rgba($discuss, .1);
  }

  &-section-number {
    // border: none;
    opacity: .3;
    background: #666;
  }

  &-section-title {
    opacity: .3;
    background: #854ad3;
  }

  &-defined-term {
    // opacity: .3;
    background: rgba($done, .3);
    // border: 1px solid $done;
  }

  &-header,
  &-footer {
    opacity: .6;
    background: black;
  }
  &-doc-title {
    opacity: .4;
    background: yellow;
  }

  &-margin-top,
  // Middle vertical ruler disabled for now (no use for it yet)
  // &-margin-middleVertical,
  &-margin-bottom {
    border-bottom: 1px solid rgba($alert, .5);
  }

  &-margin-left,
  &-margin-middleHorizontal,
  &-margin-right {
    border-left: 1px solid rgba($alert, .3);
  }

}
