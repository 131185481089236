/*
  PAGE : PROFILE
*/

/*
  Notifications
*/

.notifications-content {
  display: contents;

  .notifications-teams {
    height: 551px;
    width: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    border-radius: 0px;
    padding-bottom: 0px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    .global-notifications-setting,
    .team-notifications-setting {
      display: flex;
      height: 40px;
      margin: -16px -23px 18px;
      border: 1px solid transparent;
      border-radius: 3px;
      cursor: pointer;

      .team-name {
        width: 202px;
        margin-top: 10px;
        font-weight: 400;
        size: 14px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .icon-global,
      .icon-users,
      .icon-chevron-right {
        margin: 12px 5px 0px;
      }

      .icon-override {
        margin: 12px 5px 0px;
      }

      &:hover {
        border-style: solid !important;
        border-color: #d9dee4 !important;
        background-color: $primary-light;

        .team-name {
          font-weight: 400;
        }
      }
    }

    .selected {
      border-style: solid !important;
      border-color: #d9dee4 !important;
      background-color: $primary-light;
      border: 1px;
      border-radius: 3px;

      .team-name {
        font-weight: 600;
      }

      &:hover {
        .team-name {
          font-weight: 600;
        }
      }
    }
  }

  .notifications-settings {
    width: 440px;
    height: 551px;
    overflow-y: auto;
    scrollbar-width: thin;
    border-radius: 0px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: relative;

    .setting-info {
      max-height: 110px;

      h4 {
        margin-top: 0px;
        display: table-cell;
      }

      small {
        margin: 0px;
      }
    }

    .notification-setting,
    .enable-team-settings {
      min-height: 40px;
      max-height: 800px;
      overflow: hidden;
    }

    .notification-setting:last-child {
      border-bottom: initial;
    }

    .enable-team-settings {
      border-bottom: 1px solid $gray-6;
      background-color: $gray-5;

      @media only screen and (max-width: 1052px) and (min-width: 767px) {
        padding-bottom: 30px;
      }
    }

    .switch-notification-setting,
    .switch-enable-team-settings {
      margin-top: -5px;

      label {
        cursor: pointer;
      }
    }

    small {
      display: block;
      margin-left: 32px;
      width: 100%;
      white-space: normal;
      line-height: 16px;
      word-wrap: break-word;
    }

    p {
      display: contents;
      color: $gray-0;
    }

    a {
      color: $outlaw;
      text-decoration: underline;
    }
  }
}
