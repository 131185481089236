// used in deal pages (accordions)
// and on source view page
.source-section {
  &.page-break {
    @include pageBreak();
  }
  //slightly special case for scope items and payment items in appendix
  //currently there's no cog icon so positioning corrects for that
  &.scope-item .activity-anchor,
  &.payment .activity-anchor {
    right: -40px;
    top: 0;
    @include mediaquery-medium {
      right: -70px;
    }
  }

  //added the not() because hover on parent appendix section shouldn't cause all comments to show
  &:hover:not(.appendix) {
    //this hover is for the outer box
    .activity-anchor {
      display: block !important;
    }
  }

  &.deleted {
    .source-words .source-text,
    .variable {
      text-decoration: line-through;
      text-decoration-color: $alert !important;
      color: $black40 !important;
      &:hover {
        text-decoration-color: darken($alert, 20%) !important;
      }
    }
    &.can-review {
      cursor: pointer !important;
      .variable {
        cursor: pointer !important;
      }
    }
  }

  .section-item-copy {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  //extra padding on first/last children for source sections
  &:first-child .section-item-copy {
    padding-top: 24px;
  }
  &:last-child .section-item-copy {
    padding-bottom: 24px;
  }

  &.signature {
    min-height: 1px;

    .parties {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include mediaquery-medium {
        flex-direction: row;
      }

      > .party {
        padding: 0;
        margin: 0;
        min-height: 100px;
        flex-basis: 100%;

        .deal-user {
          position: relative;

          .sig-stamp {
            @extend .font_sans;
            font-size: 1.2rem;
            color: $discuss;
            margin-top: 5px;
            .stamp-label {
              font-weight: 700;
              margin-right: 10px;
            }
            .stamp-date {
              font-weight: 500;
            }
          }
        }

        @include mediaquery-medium {
          flex-basis: 50%;
          max-width: 50%;

          &:nth-child(odd) {
            padding-right: 16px;
          }
          &:nth-child(even) {
            padding-left: 16px;
          }
        }
      }
    }

    // Read Only overwrites
    &.readonly {
      color: $black40;

      .parties > .party {
        .deal-user .sig-stamp {
          color: inherit;
        }
        .no-user {
          border-color: $black20;
          background-color: rgba($black20, 0.05);
          &:hover {
            border-color: $black20;
          }

          span {
            cursor: not-allowed;
            color: $black40;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

// Moved from the old "scope" section/style which no longer exists!🎉
.appendix {
  &.page-break {
    @include pageBreak();
  }

  .items-container {
    margin-top: 16px;
  }

  .appendix-linked {
    display: flex;
    svg {
      margin-right: 5px;
    }
    .linked-url {
      text-decoration: underline;
    }
  }

  &.readonly {
    color: $black40;
  }
}
