.btn-help {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  border-radius: 30px;
  width: 50px !important;
  height: 50px !important;
  outline: none;

  svg {
    path {
      fill: $white;
    }
  }
}
