.react-autosuggest__container {
  width: 100%;
  position: relative;
  border-radius: 4px;

  //input field
  .react-autosuggest__input {
    //reset bootstrap
    border: 0;
    border-radius: inherit;

    //mobile and default styles
    width: calc(100% - 40px); //50px is the X button
    @extend .font_sans;
    font-size: 1.3rem;
    height: $search-bar-height-mobile;
    background-image: url(/assets/svg/20x20-search.svg);
    background-repeat: no-repeat;
    background-position: 18px center;
    background-size: $search-bar-icon-size;
    padding-left: 50px; //push input past icon (18px space + 15px icon + 18px space)
    font-size: 1.4rem;
    font-weight: 400;

    //desktop styles
    @include mediaquery-medium {
      height: $search-bar-height-desktop;
    }

    &.variableSearch {
      background-image: url(/assets/svg/20x20-fields-edit.svg);
    }

    //reset bootstrap
    &:focus,
    &:active {
      outline: 0;
    }
    &::placeholder {
      opacity: 1;
      color: $black40;
    }
  }

  .react-autosuggest__suggestions-container {
    display: none;
    z-index: 10;
    text-align: left;
    position: absolute;
    background: $white;
    width: inherit;
    border-radius: 0;

    //make suggestions container visible
    &.react-autosuggest__suggestions-container--open {
      display: block;
    }

    //effecting icons inside of:
    .icon {
      flex-basis: $search-bar-icon-size; //18+16+18
      margin: 0 18px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .react-autosuggest-searchbar-default,
    .react-autosuggest-searchbar-title,
    .react-autosuggest__suggestion {
      cursor: default;
    }

    //press enter to search
    .react-autosuggest-searchbar-default,
    .react-autosuggest-searchbar-title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 1.3rem;
      color: $black80;
      padding: 12px 0; //should be 10px but its the first one

      .text-content {
        b {
          font-weight: 700;
        }
        span {
          color: #8c98a5;
        }
      }
    }

    .react-autosuggest-searchbar-variable {
      background: none;
      z-index: 1;
      background-color: #252f3d;
      display: flex;
      border-bottom: 1px solid lighten(#252f3d, 8%);
      cursor: pointer;

      .prompt {
        display: flex;
        background-color: #252f3d;
        font-size: 1.1rem;
        @extend .font_sans;
        @extend .font_size_xs;
        color: $black40;
        padding: 5px 15px;

        .variable-query-title {
          color: $white;
        }
      }

      .instruction {
        margin-left: auto;
        display: flex;
        background-color: #252f3d;
        font-size: 1.1rem;
        @extend .font_sans;
        @extend .font_size_xs;
        color: $black40;
        padding: 5px 15px;

        .variable-query-title {
          color: $white;
        }
      }
    }

    //top keyline between input and first result
    .react-autosuggest-searchbar-default {
      //border-top: 1px solid #8C98A5;
    }

    .react-autosuggest__suggestion {
      border-top: 1px solid #d9dee4;
      //.suggestion-wrapper is the inner div that needs the flexbox set to it.

      .suggest-type {
        color: #8c98a5;
        padding-left: 5px;
      }

      .suggestion-wrapper {
        font-weight: 400; //set default
        display: flex;
        flex-direction: row;
        padding-top: 12px;
        padding-bottom: 12px;

        .highlight {
          font-weight: 700;
        }

        .text-content {
          //everything else is inside this div
          padding-right: 15px;
          width: 100%;

          .title {
            font-weight: 400;
            font-size: 1.3rem;
            color: $black80;
            width: inherit;
            position: relative;
            &.highlight {
              font-weight: 700;
            }

            .statusLabel {
              position: absolute;
              top: 0px;
              right: 0;
              &.inline {
                position: relative;
                right: auto;
              }
            }

            .ingest-docx {
              opacity: 0.25;
              width: 11px;
              height: 11px;
              vertical-align: inherit;
              margin-left: 6px;
              margin-top: auto;
            }
          }

          &.status-type {
            .statusLabel {
              top: -1px;
            }
          } //additional tweak

          .subtitle {
            font-size: 1.1rem;
            color: $black40;
          }
          .full-text-hit {
            color: $black40;

            .full-text-title {
              font-weight: 700;
            }
          }
        }
      }
      &.react-autosuggest__suggestion--highlighted {
        background: $black3;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .react-autosuggest-searchbar-footer {
    font-size: 1.2px;
    color: $black20;
    cursor: pointer;
  }

  //tag wrapper,
  .tag-wrapper {
    background: $black2;
    border-top: 1px solid $black10;
    padding: 5px 15px;
  }
}
