// This is for the toggle-able individual comment popovers
.popover-activity-view {
  width: 240px;
  @extend .font_sans;
  //z-index for comment popover must match the dealheader z-index. ($layer-fixedbars)
  //See section 4 here for more info https://www.freecodecamp.org/news/4-reasons-your-z-index-isnt-working-and-how-to-fix-it-coder-coder-6bc05f103e6c/
  z-index: $layer-fixedbars;

  .activity-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.2rem;
    padding: 8px;
    border-bottom: 1px solid $black10;

    .activity-title {
      color: $black40;
      font-weight: 700;
      flex: 1;
    }

    button.resolve,
    button.clear {
      color: $discuss;
      text-decoration: underline;
      z-index: 10;
      border: none;
      padding: 0;
      margin: 0;
      font-weight: 600;
      &:hover,
      &:active,
      &:hover:active {
        color: $black80;
      }
      &.clear {
        margin-right: 16px;
        position: relative;
        &::after {
          content: '';
          background: $black10;
          width: 1px;
          height: 100%;
          position: absolute;
          right: -8px;
        }
      }
    }
  }

  .popover-content {
    padding: 0;
    // Only shows when logged in as guest; without this there's no way to close the popover!
    .close-popover {
      top: 10px;
      right: 12px;
    }
  }

  .activity-list {
    max-height: 240px;
    overflow: scroll;

    .activity {
      position: relative;
      width: 100%;
      background-color: $white;
      padding: 10px;

      &:last-child {
        border-bottom: none;
      }

      .activity-meta {
        align-items: center;
        display: flex;

        .initials {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          background: gray;
          color: $white;
          font-size: 12px;
          font-weight: 700;
          align-items: center;
          display: flex;
          justify-content: center;
        }
        .name-details {
          flex: 1;
          padding-left: 10px;
          .name {
            display: block;
            font-weight: 600;
            font-size: 12px;
            color: $black60;
            line-height: 1;
            padding-top: 3px;
          }
          .party,
          .time {
            font-size: 10px;
            color: $black30;
            font-weight: normal;
          }
        }
      }
      .action {
        position: absolute;
        right: 4px;
        top: 20px;
        opacity: 0.7;

        position: absolute;

        &.reject {
          color: $discuss;
        }
        &.submit {
          color: $done;
        }
        &.update {
          color: $pending;
        }
      }
      .action-edit,
      .action-cancel {
        margin-left: 5px;
        color: $discuss;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .action-edit {
        display: none;
      }

      .message {
        padding: 8px 28px 0px 0px;
        position: relative;
        color: $black80;
        font-size: 13px;

        &.editing {
          background: #fdf9e8;
        }

        &.editable:hover {
          background: #fdf9e8;
          .action-edit {
            display: inline-block;
          }
        }
      }
      &.auto {
        background: $black3;
        .message {
          color: $black80;
          font-weight: 600;
          font-size: 12px;
          padding: 0;

          .time {
            font-size: 10px;
            color: $black30;
            font-weight: normal;
          }
        }
      }
    }
  }

  .#{$dmp-alert} {
    padding: 10px;
    border-top: 1px solid $black10;
  }

  .activity-footer {
    position: relative;
    background-color: $black2;
    padding: 5px;
    text-align: right;
    border-top: 1px solid $black10;
    //if there are no comments yet, we don't need a top border because we have bottom border from header
    &.no-comments {
      border-top: none;
    }

    textarea {
      height: 30px;
      font-size: 1.3rem;
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 5px;
      margin-bottom: 5px;
      resize: vertical;
      transition: all 0.5s;
      // &.expanded {
      //   height: 80px;
      //   transition: all .5s;
      // }
    }
    button {
      margin-bottom: 0;
    }
    .loader {
      position: absolute;
      left: 5px;
      bottom: 12px;
      opacity: 0.5;
    }
  }
}
