/*
  DMP COMPONENT: MODAL CONFIRM
*/

$dmp-modal-confirm: '#{$dmp}-modal-confirm';

/*
  Regular backdrops are 1040, but since Modal Confirm can likely render above
  already opened Modals, we want it to be displayed over the previous Modal
  so we bump both the backdrop and the dialog by 20.
*/

.#{$dmp-modal-confirm} {
  z-index: 1070;

  &-backdrop {
    z-index: 1060;
  }
}
