/*
  DMP COMPONENT: MENU ITEM
*/

$dmp-menu-item: '#{$dmp}-menu-item';

/*
  Important:
    Menu Items should always be inside a Dropdown Menu component.
    That's why we prepend the .dropdown-menu (also to overwrite bootstrap styles).
*/
.dropdown-menu .#{$dmp-menu-item} {
  line-height: 1.25em;

  svg {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
  }

  &-header {
    color: $gray-2;
    font-size: $font-size-small;
    padding: 7px 12px;
  }

  &-divider {
    margin: 4px 0;
  }

  &-border {
    border-bottom: 1px solid $black10;
  }

  &-info {
    color: $gray-2;
  }

  > a {
    padding: 7px 12px;
  }

  /*
    Active state
  */
  &.active a {
    font-weight: 700;
    .#{$dmp-menu-item}-info {
      font-weight: normal;
      color: $gray-dark;
    }
  }

  /*
    Disabled state overrides bootstrap styles
  */
  &.disabled {
    opacity: 0.2;
    a {
      color: inherit;
    }
  }

  /*
    With Icon
  */
  &-icon {
    position: relative;

    > a {
      padding-left: 32px;
    }

    .dmp-i {
      position: absolute;
      left: 12px;
      margin-top: 2px;

      &-color {
        margin-top: 4px;
      }
    }
  }

  /*
    Disabled state
  */
  &-icon.disabled {
    .dmp-i {
      fill: $gray-2;
    }
  }
}
