/*
  Dropdown: Template Selector
*/

.dd-template-selector {
  .dmp-dd-menu {
    max-height: 240px;
    width: 100%;
    min-width: 230px;
  }
  .dropdown-header {
    white-space: normal;
    word-break: break-word;
  }
}
