/*
  This is temporary, it should ... be cleaned up, structured w/ the rest and whatever no time.
*/

.template-editor.template-editor-pdf {
  .pdf-view {
    margin-top: 10px;
  }

  .template-toolbar {
    box-shadow: $toolbar-box-shadow;

    .btn-tool {
      align-self: stretch;
      min-width: 40px;
      min-height: 100%;
    }

    .btn-tool .dmp-dd-toggle {
      width: 100%;
      height: 100%;
    }

    .btn-deal-status {
      .status-dot {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: orange;
        margin-right: 10px;
      }
    }

    .template-toolbar-rightside {
      display: flex;
      align-items: center;
      padding-right: 10px;

      .btn-tool .count {
        color: $info;
      }
    }
  }
}
