.#{$dmp-popover} {
  &.popover-lens-editor {
    .panel-tabs {
      margin: -15px 0 10px 0;
    }

    .variable-filter {
      .values {
        display: flex;
        align-items: center;
        input {
          margin-bottom: 0;
        }
        .and {
          margin: 0 5px;
        }
        margin-bottom: 4px;
      }
    }

    .lens-conditions-info {
      margin-bottom: 10px;
    }

    .lens-condition {
      position: relative;
      padding: 0 10px 5px;
      border: 1px solid $black10;
      margin-bottom: 10px;
      .remove {
        position: absolute;
        right: 8px;
        top: 8px;
        path {
          fill: $black20;
        }
        &:hover {
          path {
            fill: $black80;
          }
        }
      }
    }
    .panel-tabs {
      .dmp-btn-link {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
