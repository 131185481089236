/*
  PAGE : PUBLIC DEAL
*/

.page-public-deal {
  background-color: $white;

  .deal-content {
    // used for terms and conditions on marketing page
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    max-width: $page-large;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    @include mediaquery-medium {
      padding: 0;
    }
  }

  .loading {
    //preloader
    text-align: center;
    padding: 48px;
    img {
      width: 40px;
    }
  }

  .intro {
    .display-buttons {
      padding-bottom: 30px;
    }
  }

  //overriding variable styles to display inline as normal (non-interactive) text
  .variable.readonly,
  .variable.complete,
  .variable {
    color: $black60;
    font-family: inherit;
    font-weight: normal;
    border: 0;
    padding: 0;
    cursor: default;
  }

  .source-view {
    border-top: 1px solid $black10;
    padding-top: 30px;

    .section-title {
      display: none;
    }
  }
}
