/*
  DMP COMPONENT: DROPDOWN
*/

$dmp-dropdown: '#{$dmp}-dd';

.#{$dmp-dropdown} {
  font-family: $font-family-base;

  &-block {
    display: block;
    flex: 1; // required when we're in a flex parent (happen often)
    > .btn {
      float: initial;
    }
  }

  &-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /*
      This is important because if we have both an icon and a caret, we must still
      display the text left aligned (and space-between is causing an issue in that specific case)
    */
    .dmp-ellipsis {
      flex: 1;
      text-align: left;
    }

    &-caret.dmp-i {
      display: inline-block;
      width: 14px;
      height: 14px;
      vertical-align: middle;
      margin-left: 4px;
      margin-right: -3px;
    }
  }

  /*
    Icon Only
  */
  &-icon-only .#{$dmp-dropdown}-toggle {
    justify-content: center;
    .#{$dmp-icon} {
      margin-right: 0;
    }

    &.#{$dmp-button}-small {
      width: 30px;
      height: 30px;
      padding: 0;
    }
  }

  /*
    Sizes
  */
  &-small {
    .#{$dmp-dropdown}-toggle {
      height: 30px;
      &.#{$dmp-button}-small {
        .#{$dmp-dropdown}-toggle-caret {
          width: 15px;
          height: 15px;
          margin-right: 0;
        }
      }
    }
  }

  /*
    Styles
  */
  &-primary {
    .#{$dmp-dropdown}-toggle-caret {
      fill: white;
    }
  }

  /*
    Pre-configured dropdowns
  */
  &-dots {
    &-push-right {
      position: relative;
      right: -10px;
    }

    > .btn.btn-link {
      justify-content: center;
      padding: 0;
      font-size: 20px;
    }

    &-faded > .btn.btn-link {
      .dmp-i {
        fill: $gray-2;
      }
      &:hover .dmp-i,
      &:active .dmp-i {
        fill: $gray-0;
      }
    }
  }

  // Disabled states
  // There must always be defined after to overwrite custom styles above
  // Most of it is defined in button.scss, we're mainly fixing caret color here
  &[disabled] {
    &.#{$dmp-dropdown}-togle-caret {
      &.dmp-i {
        fill: $gray-2;
      }

      &.dmp-btn-primary,
      &.dmp-btn-danger {
        &.dmp-i {
          fill: $gray-2;
        }
      }
    }
  }

  /*
    Dropdown Menu
  */
  &-menu {
    // Overwrite Bootstrap 160px default
    //min-width: 60px;
    min-width: 160px;
    max-width: 400px;

    // Set general default max height
    // we might need to make it adjustable
    max-height: 40vh;
    overflow-y: auto;

    background-color: $white;

    > li > a {
      white-space: normal;
      outline: none;

      .rhs {
        float: right;
        color: $black40;
      }
    }
  }
}
