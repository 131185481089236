/*
  DMP COMPONENT: TAG
*/

$dmp-tag: '#{$dmp}-tag';

.#{$dmp-tag} {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid #e0e0e3;
  border-radius: 2px;
  height: 30px;
  line-height: 28px;
  padding: 0 8px 0;
  color: #383e46;

  font-size: 13px;
  font-weight: 600;

  // So that tags display well when rendered inline, add margin
  margin-right: 8px;
  margin-bottom: 8px;

  &:hover {
    border-color: $black20;
    box-shadow: 0 1px 1px 0 rgba($black100, 0.05);
  }

  // Tag.Label
  .#{$dmp-tag}-label {
    font-weight: 600;
    opacity: 0.4;
    padding-right: 4px;
    text-transform: capitalize;
  }

  // Removable tags X styling
  &-removable {
    padding-right: 30px;
    cursor: pointer;

    .#{$dmp-tag}-btn-remove {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;

      svg {
        width: 15px;
        height: 15px;
        path {
          fill: $black40;
        }
      }
    }

    .#{$dmp-tag}-btn-remove:hover svg path {
      fill: $black60;
    }
  }

  // Sizes
  &-xsmall {
    line-height: 1.2rem;
    font-size: 1.2rem;
    font-weight: 500;
    height: 20px;
    line-height: 18px;
    padding: 0 5px;

    margin-right: 3px;
    margin-bottom: 3px;

    &.#{$dmp-tag}-removable {
      padding-right: 20px;

      .#{$dmp-tag}-btn-remove {
        right: 5px;
        display: flex;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    .dmp-i-small {
      margin-bottom: -1px;
      margin-left: -2px;
    }
  }

  // Available colors
  &-color {
    &-white {
      background-color: $white;
    } // Default
    &-gray {
      background-color: #f5f5f6;
    }
    &-error {
      border-color: $color-red;
      background: $state-danger-bg;
    }
  }

  // Display block (full container width)
  &-block {
    display: flex;
    label {
      display: block;
    }
  }
}
