/*
  DMP COMPONENT: POPOVER
*/

$dmp-popover: '#{$dmp}-popover';
$dmp-popover-padding: 20px;
$dmp-popover-padding-small: 11px;
$dmp-popover-padding-small-side: 16px;

.#{$dmp-popover} {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.1);

  &-title {
    font-size: 13px;
    color: $gray-dark; // $gray-2
    font-weight: 600;
    padding-bottom: 10px;
  }

  & > .popover-content {
    border-radius: 4px;
    padding: $dmp-popover-padding;
    @extend .font_size_xs;
    text-align: left;

    // Will remove once we integrate it into @dmp/Popover
    .close-popover {
      top: 25px;
      opacity: 0.25;
      &:hover,
      &:active {
        opacity: 0.4;
      }
    }
    .sharing-status-options {
      margin-top: -10px;
    }
  }

  // Adjust default position
  &.bottom {
    margin-top: 10px;
  }

  /*
    Sizes
  */
  &-small > .popover-content {
    @extend .font_size_xs;
    font-weight: 500;
    padding: $dmp-popover-padding-small $dmp-popover-padding-small-side;
    small {
      font-weight: initial;
    }

    // Will remove once we integrate it into @dmp/Popover
    .close-popover {
      top: 16px;
    }
  }

  &-content {
    // Custom content adjustments (should be moved to form adjustments once we have it)
    form {
      .form-group {
        display: block; // no flex
        margin-bottom: 6px;

        &.actions {
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
      label,
      .control-label {
        color: $gray-dark;
        font-weight: 500 !important;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
    // Override bootstrap for those that have titles
    .popover-title {
      padding: 0 0 6px;
    }
  }

  &-no-title {
  }

  /*
    Styles
  */
  &-dark {
    //box-shadow: none;
    background-color: #252f3d;
    border-width: 0;
    padding: 0;

    // Arrow colors
    &.popover.right > .arrow {
      border-right-color: $primary-dark;
      &:after {
        border-right-color: $primary-dark;
      }
    }
    &.popover.left > .arrow {
      border-left-color: $primary-dark;
      &:after {
        border-left-color: $primary-dark;
      }
    }
    &.popover.bottom > .arrow {
      border-bottom-color: $primary-dark;
      &:after {
        border-bottom-color: $primary-dark;
      }
    }
    &.popover.top > .arrow {
      border-top-color: $primary-dark;
      &:after {
        border-top-color: $primary-dark;
      }
    }

    .#{$dmp-popover}-content {
      color: #fff;

      // links
      & > a {
        color: inherit;
      }
    }
  }

  &-light {
    background-color: $white;
    border-color: transparent;
  }

  /*
    List style popover
  */
  &-list {
    // List items will handle padding
    & > .popover-content {
      padding: 0;
    }

    &-item {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #5a6573; // $gray-dark
      line-height: 1.5em;
      padding: $dmp-popover-padding;

      &:last-child {
        border-bottom-width: 0;
      }

      &-content {
        max-width: 100%;
        flex-grow: 1;
      }
    }
  }

  // List style small
  &-list.#{$dmp-popover}-small .#{$dmp-popover}-list-item {
    padding: 8px 10px;
  }
}
