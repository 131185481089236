$button-on: 0.6;

.content-section {
  display: block;
  position: relative;
  clear: both;

  &.page-break,
  &.header-footer-break {
    @include pageBreak();
  }

  // +/- button to show summarized contract sections
  .source-toggle {
    position: absolute;
    right: 20px;
    bottom: 30px;
    opacity: $button-on;

    @include mediaquery-medium {
      opacity: 0;
      right: -20px;
      bottom: auto;
      top: 7px;
    }
    cursor: pointer;
  }
  &:hover {
    @include mediaquery-medium {
      .source-toggle {
        opacity: $button-on;
      }
    }
  }
  &.expanded {
    .source-toggle {
      opacity: $button-on;
    }
  }

  .source-words {
    .content-text {
      width: inherit;
    }
  }

  .summary-words {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: $white;
    position: relative;
    padding: 5px 0px 5px 0px;

    .content-number {
      min-width: 32px;
      cursor: default;
    }
    .content-text {
      width: inherit;
      cursor: default;
      .bold {
        font-weight: 600;
      }
      .underline {
        text-decoration: underline;
      }
      .italic {
        font-style: italic;
      }
    }
  }

  &.has-children:not(.expanded) {
    .content-text {
      cursor: pointer;
    }
  }

  .source-children {
    position: relative;
    z-index: 99;
    background-color: $black2;
    clear: both;
    display: block;
    padding: 10px;
    border-left: 3px solid $gray-3;

    .source-section {
      .source-number {
        //strip out number in 'overview' view
        display: none;
      }
    }
  }
}
