.user-auth {
  .action {
    width: 500px;
    min-height: 280px;
    padding: 40px 0;
    @extend .papershadow;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      height: 34px;
    }
    h3 {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 30px;
    }
    p {
      span {
        font-weight: 500;
      }
    }

    .invalidLink {
      button {
        width: 100%;
      }
      .contents {
        margin-bottom: 20px;
      }
    }

    .form {
      width: 250px;
      text-align: left;

      .contents {
        margin-bottom: 10px;
      }

      button {
        width: 100%;
        margin-top: 15px;
      }
    }
    .resetPasswordText {
      margin-bottom: 10px;
    }
    .match {
      color: green;
    }
    .no-match {
      color: red;
    }
  }

  .status {
    position: absolute;
    width: 100%;
    top: calc(50% + 160px);
    text-align: center;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 30px;
    }
  }
}
