/*
  Overview view
*/
.deal-view {
  //fixes bug when big CTA pops over end of deal-view .draft, .review, .sign .done
  margin-bottom: $deal-header-height;
  @include mediaquery-medium {
    margin-bottom: 0;
  }

  // .todo has no CTA so remove additional padding
  &.todo {
    margin-bottom: 0;
  }

  //change font on [overview section]
  .scope-section {
    @extend .font_sans;
    color: $black60;
  }
}

.pdf-view {
  margin-top: 100px;
  @include mediaquery-medium {
    margin-top: 60px;
  }
}

.case-data-btn {
  margin-left: auto;
  display: block;
}

.deal-section {
  clear: both;
  position: relative;
  padding-top: 40px; // this adds some space between deal sections
  z-index: 0;
  &:hover {
    z-index: 101;
  }

  &:first-child {
    padding-top: 0;
  }

  .deal-status-todo {
    // for summary sections to show # of child items that need attention
    position: absolute;
    left: -20px;
    top: 6px;
    z-index: $layer-color-labels;
    transform: scale(0.3);
    @include mediaquery-medium {
      transform: scale(0.75);
      left: -32px;
    }
  }
  //fix alignment on colorlabels on scope and payment
  &.scope,
  &.payment {
    .deal-status-todo {
      top: 0px;
    }
  }
}

/*
  Deal Panel Item
  Since they're not 100% of the time inside a DealPanel (for now / DealUserBlock)
  We'll define it globally for now.
*/
.deal-panel-item {
  padding: 10px 15px;
  background-color: $white;
  border-left: 0px solid;

  &-border-bottom {
    border-bottom: 1px solid #d4dbe4;
  } // $gray-3

  &-type-primary {
    border-left-color: $outlaw;
  }
  &-type-warning {
    border-left-color: #f6cb6b;
  }
  &-type-info {
    border-left-color: $info;
  }
  &-type-danger {
    border-left-color: #ee6662;
  }

  &-active {
    border-left-width: 2px;
  }

  &-transparent {
    background-color: transparent;
  }
}

/*
  Same is true for DealVersionBlock, which effectively extends DealPanelItem
  But is also used elsewhere (DeleteDealVersion)
*/
.deal-file-block {
  padding-left: 10px;
  display: flex;
  padding-right: 15px;
  position: relative;

  .file-icon,
  .dmp-loader {
    margin: 2px 10px 0 0;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .file-info {
    flex: 1;
    max-width: calc(100% - 25px);
    position: relative;

    .file-topline {
      display: flex;
      align-items: center;
      margin-bottom: 3px;

      .file-title,
      .version-title {
        cursor: pointer;
        color: $black90;
        font-weight: 600;
        font-size: 1.4rem;
        margin-right: 10px;
        overflow-wrap: anywhere;
      }

      .spacer {
        flex: 1;
      }

      .statusLabel {
        min-width: 40px;
        padding: 2px 4px 1px;
        margin-right: 8px;
      }
    }
    .file-meta {
      display: flex;
      align-items: center;
      margin-bottom: 3px;

      svg {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        margin-top: -1px;
        path,
        rect {
          fill: $black20;
        }
      }
      span {
        font-size: 1.2rem;
        overflow-wrap: anywhere;
      }
    }
  }

  &.interactive,
  &.downloadable,
  &.selected {
    .file-title {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  button.commit {
    float: right;
    margin: 10px 0 5px;
  }

  &.selected {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: -1px;
      bottom: -1px;
      width: 3px;
      background: $black100;
      z-index: 1;
    }
  }

  &.pending {
    .file-topline .file-title {
      color: $discuss;
      text-decoration: none;
      cursor: text;
    }
    svg path,
    svg rect {
      fill: $discuss;
    }
  }
}

.change-list {
  margin-top: 8px;
  padding: 4px 6px;
  font-size: 1.2rem;
  background: #e8f1fe;
  border-left: 3px solid rgba(103, 160, 248, 0.25);
  width: 100%;
  color: $discuss;

  .cl-title {
    font-weight: 700;
  }
  .cl-item {
    line-height: 1.2;
    display: flex;
    width: 100%;
    .cl-change {
      flex: 1;
    }
    .cl-page {
      padding-left: 5px;
    }
  }
}

// .add-item is a child of .deal-view but keep this code outside
// because it is used in multiple places, overview, summary and preview mode
.add-item,
.browse-items,
.load-diff {
  &.add-ai-block {
    @include element($discuss);
    svg {
      margin-top: -2px;
      vertical-align: middle;
      path {
        fill: $discuss;
      }
    }

    &.ai-error {
      @include element($alert);
      svg {
        path {
          fill: $alert;
        }
      }
    }

    &.disable-action {
      pointer-events: none;
      @include element($gray-3);
      svg {
        path {
          fill: $gray-3;
        }
      }
    }
  }

  display: inline-block;
  cursor: pointer;
  position: relative;
  @extend .font_sans;
  font-size: 1.4rem;
}

.modal-read-only-deal {
  .modal-dialog {
    width: 360px;

    .modal-content .modal-body {
      .wrapper {
        padding: 30px;
      }

      .btn-block + .btn-block {
        margin-top: 10px;
      }

      .icon {
        border: 2px solid $black30;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        margin: 0 auto;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;

          g {
            stroke: $black30;
          }
        }

        &.success {
          border-color: #46ca8c;
          svg g {
            stroke: #46ca8c;
          }
        }
      }

      p {
        font-size: 14px;
        margin-bottom: 15px;
      }

      .form-request {
        .form-group {
          margin-bottom: 10px;
        }
        .btn-primary {
          margin-top: 20px;
        }

        .btn-group {
          display: block;
        }
      }
    }
  }
}

.modal-guest-upgrade {
  .modal-dialog {
    max-width: 460px;
  }

  .modal-content {
    border-radius: $border-radius-base;

    .modal-header {
      border: 0;
      .close {
        color: $gray-dark;
      }
    }

    .modal-body {
      padding: 40px 20px 50px;

      .wrapper {
        max-width: 360px;
        margin: 0 auto;
        padding: 0;
      }

      h3 {
        font-weight: 600;
        font-size: 1.8rem;
        margin-top: 5px;
        margin-bottom: 20px;
      }

      h2 {
        font-weight: 500;
        font-size: 1.8rem;
        color: #2dcb8d;
        margin-top: 8px;
        margin-bottom: 20px;
      }

      .intro {
        .flex-row .dmp-alert {
          margin-bottom: 10px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.connect-empty-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.connect-panel {
  .count-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
  }
  .count-amount {
    align-self: center;
  }
  .connect-source-link {
    cursor: pointer;
  }
  .connect-container {
    width: 100%;
    display: flex;

    .connect-item {
      width: 100%;
      flex-direction: row;
      padding-left: 5px;

      .connect-item-row {
        display: flex;
        font-size: 13px;
        line-height: 21px;
        color: #666666;
        font-weight: 500;
        .dropdown-right-align {
          margin-left: auto;
        }
        .left-section {
          margin-top: 3px;
          margin-right: 5px;
        }
        .blue-icon {
          fill: #1798c1;
        }
        .right-section {
          text-transform: capitalize;
          font-weight: 600;
        }
        .row-left-item {
          min-width: 100px;
        }
        .row-right-item {
          font-weight: 400;
        }
      }
    }
  }
}

.linked-appendix-selector {
  text-align: left;
  font-family: 'proxima-nova';
}
