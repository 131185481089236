.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .error-block {
    background-color: $gray-0;
    width: 500px;
    padding: 15px;
    gap: 10px;
    border-radius: 4px;

    @include mediaquery-xsmall {
      margin: 0 10px;
    }

    @include mediaquery-small {
      margin: 0 10px;
    }

    .header {
      color: #ffffff;
      size: 14px;
      svg {
        margin-right: 12px;
        vertical-align: middle;
        margin-top: -2px;
        path {
          fill: $gray-3;
        }
      }
    }
    .message {
      margin-left: 27px;
      color: $gray-3;
      size: 13px;
    }
  }
}
