/*
  DMP COMPONENT: SETTING
*/

$dmp-setting: '#{$dmp}-setting';

.#{$dmp-setting} {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &-label {
    flex: 1;
    font-size: 1.3rem;
    padding-right: 20px;

    .setting-title {
      font-weight: 700;
    }
    .setting-subtitle {
      color: $black60;
      font-weight: normal;
    }
  }

  &-ui {
    flex: 3;
    display: flex;
    align-items: center;

    .radio {
      margin: 0;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &-actions {
    display: flex;
    width: 100%;
    align-items: center;
    .spacer {
      flex: 1;
    }

    button {
      margin-left: 10px;
    }
  }
}
