/*
  DMP COMPONENT: SWITCH
*/
@import '~rc-switch/assets/index.css';

$dmp-switch: '#{$dmp}-switch';
@keyframes rcSwitchOn {
  50% {
    transform: scale(1.1);
  }
}

.#{$dmp-switch} {
  height: 20px;

  label {
    display: inline-block;
    font-weight: normal;
    padding-top: 1px;
  }

  span {
    color: $gray-0;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  .rc-switch,
  .rc-switch-checked {
    width: 33px;
    height: 20px;
    bottom: 1px;
    margin-left: -1px;
    border: 1px solid $black40;
    border-radius: 10px;
    background-color: $white;
  }

  .rc-switch:after {
    width: 14px;
    height: 14px;
    top: 2px;
    left: 2px;
    border-radius: 10px;
    background-color: $black40;
    box-shadow: none;
  }

  .rc-switch-checked {
    border: 1px solid $outlaw;
    background-color: $outlaw;
  }

  .rc-switch:focus {
    box-shadow: none;
  }

  .rc-switch-inner {
    color: $white;
  }

  .rc-switch-checked:after {
    left: 15px;
    background: $white;
  }

  .rc-switch-disabled {
    background: $gray-4;
    border: 1px solid $gray-3;
    border-radius: 10px;
  }

  .rc-switch-disabled:after {
    background: $gray-3;
    border-radius: 10px;
  }

  .rc-switch-disabled {
    &.rc-switch-checked {
      bottom: 1px;
      background: $gray-3;
      border: 1px solid $gray-3;
      border-radius: 10px;
    }
  }

  .rc-switch-disabled {
    &.rc-switch-checked:after {
      background: $gray-5;
      border-radius: 10px;
    }
  }

  //size
  &-small {
    span {
      font-size: 13px;
      margin-left: 10px;
    }

    .rc-switch,
    .rc-switch-checked {
      width: 23px;
      height: 14px;
    }

    .rc-switch:after {
      width: 10px;
      height: 10px;
      top: 1px;
      left: 1px;
    }

    .rc-switch-checked:after {
      left: 10px;
      background: $white;
    }
  }

  //Change disabled text color
  &-disabled {
    span {
      color: $gray-2;
    }
    // This enables a Switch to *display* as disabled by manually setting a className of dmp-switch-disabled on the Switch
    // This is necessary in order to make a tooltip work without actually using the disabled prop
    // (because if we use the disabled prop, the switch doesn't receive mouse events and the tooltip doesn't show)
    button.rc-switch {
      pointer-events: all;
      background: $gray-4;
      border: 1px solid $gray-3;
      cursor: not-allowed;
      &:after {
        background: $gray-5;
        cursor: not-allowed;
      }
    }
  }
}
