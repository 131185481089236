/*
  DMP COMPONENT: BUTTON TOOLBAR
*/

$dmp-btn-toolbar: '#{$dmp}-btn-toolbar';

.#{$dmp-btn-toolbar} {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;

  /*
    Any direct descendant of the button toolbar will receive
    the margin and alignment styling (<div>, <Button>, <Dropdown>, etc.)
  */
  & > * {
    margin-left: 5px;
    margin-right: 5px;
  }

  /*
    We mught also allow splitting buttons in groups.
    So apply the same margin mechanic to groups.
  */
  &-group {
    display: flex;
    align-items: flex-start;
    & > * {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  /*
    Alignments
  */
  &-fill {
    & > * {
      flex: 1;
      min-width: 0;

      // Special case for dropdowns (nested buttons)
      &.dmp-dd .btn {
        width: 100%;
      }
    }
  }

  /*
    Alignments
  */
  &-align {
    // default
    &-left {
      justify-content: flex-start;
      & > *:first-child {
        margin-left: 0px;
      }
      & > .#{$dmp-btn-toolbar}-group > *:first-child {
        margin-left: 0px;
      }
    }
    &-right {
      justify-content: flex-end;
      & > *:last-child {
        margin-right: 0px;
      }
      & > .#{$dmp-btn-toolbar}-group > *:last-child {
        margin-right: 0px;
      }
    }
    &-center {
      justify-content: center;
    }
    &-between {
      justify-content: space-between;
      & > *:first-child {
        margin-left: 0px;
      }
      & > *:last-child {
        margin-right: 0px;
      }
      & > .#{$dmp-btn-toolbar}-group > *:first-child {
        margin-left: 0px;
      }
      & > .#{$dmp-btn-toolbar}-group > *:last-child {
        margin-right: 0px;
      }
    }
  }

  /*
    Extras
  */
  // When we have a loader present in the ButtonToolbar, we'll want to center it vertically 99% of the time
  .dmp-loader {
    align-self: center;
  }
}
