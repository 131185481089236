/*
  DMP COMPONENT: VALIDATOR
*/

$dmp-validator: '#{$dmp}-validator';

.#{$dmp-validator} {
  display: flex;

  // Size
  img,
  svg {
    width: 18px;
    height: 18px;
  }

  .dmp-loader {
    margin-top: 1px;
  }

  // Colorize svgs by default
  &-colored {
    .valid path,
    .valid rect {
      fill: $done;
    }
    .invalid path,
    .invalid rect {
      fill: $alert;
    }
    .disabled path,
    .disabled rect {
      fill: $black20;
    }
  }
}

/*
  Required on the parent to properly align @dmp/Validator
  when it's part of an input or a container.
*/
.#{$dmp-validator}-container {
  position: relative;

  // Adding padding to the input so that we can see the validator icon
  input.form-control {
    padding-right: 28px;
  }

  .#{$dmp-validator} {
    position: absolute;
    right: 8px;
    top: calc(50% - 9px);
  }
}
