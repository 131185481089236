/*
  Bootstrap 4 .d-flex utilities

  This is copied from Boostrap 4 repository.
  Once we migrate to Bootstrap 4, we'll be able toremove this
  but in the meantime, we can already use it.

  How to use: https://getbootstrap.com/docs/4.0/utilities/flex/
*/

// Bootstrap functions
//
// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.
// https://github.com/twbs/bootstrap/blob/a8883a3b96a94d9c096d1047564eeb87de7e79e7/scss/_functions.scss

// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null or unit($num) == '%' or unit($prev-num) == '%' {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Used to ensure the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map, $map-name: '$grid-breakpoints') {
  @if length($map) > 0 {
    $values: map-values($map);
    $first-value: nth($values, 1);
    @if $first-value != 0 {
      @warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
    }
  }
}

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_variables.scss

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

//
// Utilities for common `display` values
//
// https://github.com/twbs/bootstrap/blob/v4.0.0/scss/utilities/_display.scss

.d-none {
  display: none !important;
}
//.d#{$infix}-inline       { display: inline !important; }
//.d#{$infix}-inline-block { display: inline-block !important; }
//.d#{$infix}-block        { display: block !important; }
//.d#{$infix}-table        { display: table !important; }
//.d#{$infix}-table-row    { display: table-row !important; }
//.d#{$infix}-table-cell   { display: table-cell !important; }
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}

//
// Utilities for toggling `display` in print
//

@media print {
  .d-print-none {
    display: none !important;
  }
  //.d-print-inline       { display: inline !important; }
  //.d-print-inline-block { display: inline-block !important; }
  //.d-print-block        { display: block !important; }
  //.d-print-table        { display: table !important; }
  //.d-print-table-row    { display: table-row !important; }
  //.d-print-table-cell   { display: table-cell !important; }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

// Flex variation
//
// Custom styles for additional flex alignment options.
// https://github.com/twbs/bootstrap/blob/v4.0.0/scss/utilities/_flex.scss

.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
