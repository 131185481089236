/*
  DMP COMPONENT: MOBILE NAV
*/

$dmp-mobile-nav: '#{$dmp}-mobile-nav';

.#{$dmp-mobile-nav} {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-mobile-nav;

  .side {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $layer-mobile-nav + 1;

    .bg {
      position: absolute;
      background-color: $outlaw-dark;
      height: 100%;
      width: 100%;
    }

    .contents {
      position: absolute;
      background-color: $white;
      height: 100%;
      width: 80%;

      .nav-header {
        padding: 15px;
        border-bottom: 1px solid $black10;
        font-size: 1.2rem;
        font-weight: 600;
        color: $black60;
      }
    }

    &.on {
      visibility: visible;
      .bg {
        transition: opacity 0.3s;
        opacity: 0.95;
      }
      .contents {
        left: 0;
        opacity: 1;
        transition: all 0.3s;
      }
    }

    &.off {
      visibility: hidden;
      .bg {
        transition: opacity 0.3s;
        opacity: 0;
      }
      .contents {
        left: -30%;
        opacity: 0;
        transition: all 0.3s;
      }
    }
  }
}
