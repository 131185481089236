.lens-checks-container {
  margin-top: -5px;
}

.display-block {
  display: flex;

  &.border-bottom {
    border-bottom: 1px solid $black10;
  }

  .left {
    margin-right: 10px;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .right {
    width: 100%;

    .lens-topline {
      display: flex;
      align-items: center;
      height: 15px;
      margin-top: 10px;
      margin-bottom: 10px;

      .item-label {
        font-size: 12px !important;
        color: $gray-0;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 15px !important;
      }

      .total-group-risk {
        margin-left: auto;
        color: $gray-0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        margin-right: 10px;
      }

      .openContent {
        margin-left: auto;
      }

      .closeContent {
        &.placeholder {
          svg {
            path {
              fill: white !important;
            }
          }
        }
      }
    }
  }
}

.lens-summary-container {
  margin-top: 10px;
}

.lens-check,
.lens-summary {
  display: flex;
  align-items: center;
  .icon-lens {
    align-self: flex-start;
    width: 15px;
    height: 15px;
    overflow: inherit;
  }

  .item-label {
    font-size: 12px !important;
    color: $gray-0;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 15px !important;
  }

  .lens-question-label {
    color: $gray-0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 5px;
  }

  .lens-filter-label {
    margin-right: 5px;
  }

  .lens-filter-label,
  .lens-filter-score {
    overflow-wrap: anywhere;
    color: $gray-0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 5px;
  }

  .lens-filter-score {
    white-space: nowrap;
    margin-right: 25px;
  }

  .spacer {
    flex: 1;
  }

  &.lens-check {
    margin-bottom: 10px;
  }

  .risk-points {
    font-size: 1.3rem;
    font-weight: 500;
    padding-right: 5px;
    margin-left: 0px;
    line-height: 21px;
    margin-right: 20px;
  }
  .has-risk {
    margin-left: 0px;
  }
  &.pass .icon-result path {
    fill: rgba($black20, 1);
  }

  .lens-info {
    width: 100%;

    .item-label,
    .item-sub {
      margin-bottom: 5px;
    }

    .lens-filter-check {
      display: flex;

      &.error {
        .lens-filter-label {
          color: $color-red !important;
        }

        svg {
          path {
            fill: $color-red !important;
          }
        }
      }

      .check {
        position: absolute;
        left: 15px;

        &.large {
          left: 120px;
        }
      }

      .lens-filter-score {
        margin-left: auto;
      }
    }
  }
}
