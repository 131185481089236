/*
  PAGE : REPORTS
*/

.page-reports {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  flex: 1;
  min-height: calc(100vh - #{$top-bar-height});

  @include mediaquery-medium {
    width: calc(100% - #{$sidebar-width}); //calculate width:100% - $sidebar-width
    margin-left: $sidebar-width; //then push outer div from the left
  }

  .reports {
    width: 100%;
    margin: 0 auto;
    padding: 20px 20px 0 20px;

    @include mediaquery-medium {
      padding: 0px 60px 0 60px;
    }

    @include mediaquery-xlarge {
      max-width: 1450px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .reports-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .title-bar {
    justify-content: space-between;

    h1 .team-name {
      color: $black40;
      margin-left: 20px;
    }

    .actions {
      > .btn,
      > .btn-group {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  .btn-add-report {
    svg {
      width: 15px;
      height: 15px;
      vertical-align: text-top;
      margin-right: 0px;
      margin-left: -2px;
      path {
        fill: $white;
      }
    }
  }
}

/*
  REPORT ITEM
  Since report items are draggable and when dragged, they're rendered in the body,
  their CSS must not be scoped by he page's selector.
*/

.report-item {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  padding-top: 25px;

  .pipeline {
    overflow-y: auto;
  }

  @include mediaquery-xlarge {
    width: 49%;
    // Fixed height on larger screen allow the dashboard to look and behave liek a grid
    height: 370px;

    .report-item-content {
      height: 100%;
      max-height: 267px;
    }
  }

  .#{$dmp-card}-title {
    position: relative;
  }

  .dmp-loader {
    height: 80%;
  }

  .dmp-data-table {
    // optically align text in table to the dmp-card-title
    .rt-th,
    .rt-td {
      &:first-child {
        padding-left: 0;
      }
    }

    .rt-th,
    .rt-td {
      &:last-child {
        padding-right: 0;
      }
    }

    .ReactTable {
      height: 267px; // Hardcoded height for the table since ReportItem have an hardcoded height
      .rt-table {
        position: absolute;
      }
    }

    // Adjust origin icon
    .rt-td {
      &.origin {
        svg {
          width: 15px;
          height: 15px;
          vertical-align: middle;
        }
      }
    }

    .rt-noData {
      width: 300px;
      text-align: center;
      font-size: 2.8rem;
      color: $black20;

      span {
        display: block;
        font-size: 1.4rem;
      }
    }
  }

  &-draggable {
    .#{$dmp-drag-handle} {
      position: absolute;
      top: -3px;
      left: -30px;
      opacity: 0;
    }

    &:hover {
      .#{$dmp-card}-title {
        .#{$dmp-drag-handle} {
          opacity: 1;
        }
      }
    }
  }

  .#{$dmp-card}-extra {
    .dropdown-toggle {
      padding: 0;
      position: relative;
      margin-right: -12px; // offset 20x20-3dots.svg so it lines up visually with the 30px margin of report box

      svg path {
        fill: $black20;
      }
      &:hover {
        svg path {
          fill: $black80;
        }
      }
    }
  }

  .state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    height: 100%;
    text-align: center;
    padding: 0 60px;
    margin: 0 auto;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid $outlaw;
      width: 58px;
      height: 58px;

      svg path {
        fill: $outlaw;
      }
    }

    h4 {
      color: $black80;
    }

    p {
      color: $black40;
    }

    &.error {
      .icon {
        border-color: $color-red;
        svg path {
          fill: $color-red;
        }
      }
    }
  }

  &-empty {
    width: 100%;
    height: 390px;

    .state {
      .icon {
        border-color: $black20;
        svg path {
          fill: $black20;
        }
      }
      h4 {
        color: $black40;
      }
    }
  }
}

#popover-dashboard-notifications {
  hr {
    margin: 8px 0;
  }

  .fg-frequency {
    .btn-group {
      display: flex;
      & > label {
        flex: 1;
      }
    }
  }

  .actions {
    .btn-turn-off {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
    }
  }
}

//Status Grid
.status-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 1rem;
  justify-content: space-between;
  margin-left: -4px;
  max-width: 100%;

  .grid-item {
    margin: 0 0 10px 0;

    &:hover {
      cursor: default;
    }
  }

  .grid-item-total {
    font-size: 18px;
  }

  .statusLabel {
    margin: 0 auto 0.5rem;
    color: #fff;
  }
}
