/*
  DMP COMPONENT: DRAG HANDLE
*/

$dmp-drag-handle: '#{$dmp}-drag-handle';

.#{$dmp-drag-handle} {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  svg {
    width: 20px;
    path {
      fill: $black40;
    }
  }

  &.disabled {
    cursor: default;
    svg path {
      fill: $black10;
    }
  }
}
