/*
  PDF EDITOR COMPONENT: ELEMENT
*/

$pdfe-element: '#{pdfe}-element';

.#{$pdfe-element} {
  position: relative;
  background: rgba($discuss, 0.1); // $gray-4
  border: 2px solid transparent;
  color: #5a6573;
  border-radius: 1px;

  &-content {
    // Important for the Signature
    height: 100%;
  }

  &-resize-handle {
    position: absolute;
    display: block;
    opacity: 0;
    background-color: $info;
    width: 8.5px;
    height: 8.5px;
    border: 1px solid #fff;
    box-shadow: 0px 5px 10px rgba(37, 47, 61, 0.1); // REMOVE 2nd pixel border
    transform: rotate(-45deg);

    &.right {
      top: 50%;
      transform: translate(0%, -50%) rotate(-45deg);
      right: -5px;
    }
  }

  &.#{$pdfe-element}-is-hover:not(.#{$pdfe-element}-readonly) {
    border-color: $info;
    .#{$pdfe-element}-resize-handle {
      opacity: 1;
    }
  }

  &.#{$pdfe-element}-readonly {
    background-color: rgba($black10, 0.25);
    cursor: not-allowed;
    .pdfe-element-content {
      pointer-events: none;
    }
  }

  &.#{$pdfe-element}-is-active {
    border-color: $info;
    .#{$pdfe-element}-resize-handle {
      opacity: 1;
    }
  }

  &.#{$pdfe-element}-draggable {
    &.#{$pdfe-element}-will-drag {
      background-color: #fff;
      border-color: #f6cb6b;

      .#{$pdfe-element}-resize-handle {
        display: none;
      }
    }

    &.#{$pdfe-element}-is-active {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &-text {
    outline: none;
    border: 0;
    width: 100%;
    padding: 0;
    cursor: text;

    // Important to kkep line-breaks when the text is coming from
    // the variable data to the PDF Element
    white-space: pre-wrap;

    // Important, we need to enforce width and avoid text bleeding out
    overflow-wrap: break-word;

    // Try to be closer to what's rendered in the PDF
    -webkit-font-smoothing: auto;

    &-readonly {
      cursor: not-allowed;
    }
  }

  &-signature {
    color: #8795a5; // $gray-2
    padding: 0;

    // For PDF Element Signature, we remove the border
    .siggy {
      height: 100%;
      border-width: 0;
      margin: 0;

      &.signed .siggy-content {
        padding: 0;
      }
    }
  }
}

/*
  Draggable Element
*/
.element-drag {
  position: absolute;

  .drag-handle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: calc(100%); // Might need to improve that
    width: 20px;
    background-color: $info;
    top: 0;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    z-index: 10;
    cursor: grab;

    .dmp-i {
      fill: $white;
      position: relative;
      left: 1px;
      width: 20px;
      height: 20px;
    }
  }

  .drag-handle:hover,
  .drag-handle:active {
    background-color: #f6cb6b;
  }

  // Only show when the field is active
  .pdfe-wrapper {
    position: relative;

    // Bug fix for resizable elements,
    // this is positionned out of the field so it creates extra padding when dragging.
    .__resizable_base__ {
      top: 0;
    }

    // always show
    .drag-handle {
      display: none;
    }
    &-is-hover .drag-handle,
    &-is-active .drag-handle {
      display: flex;
    }

    // Adjust content with padding to account for the .drag-handle
    .handle-pusher {
      padding-left: 20px;
    }
  }
}

.pdfe-wrapper {
  &.unassigned,
  &.required {
    .drag-handle {
      background-color: rgba($alert, 0.6);
    }
    .pdfe-element {
      border-color: rgba($alert, 0.6);
      background: none;
      .pdfe-element-content {
        background-color: rgba($alert, 0.1);
        .sign-text-prompt,
        .variable,
        .incomplete {
          color: $alert !important;
          text-decoration-color: $alert !important;
          text-decoration: underline;
        }
      }
    }
  }
  .variable,
  .variable:hover {
    border-bottom: none;
    &.incomplete {
      text-decoration: underline;
    }
  }
}
