/*
  Dropdown: Deal Status Selector
*/

.dd-deal-status {
  margin-left: 4px;

  .dmp-menu-item.disabled {
    opacity: 100;
    .menu-content {
      opacity: 0.2;
    }
  }

  .top-line {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
    }

    .dmp-swatch {
      margin-right: 8px;
    }

    .dmp-i {
      margin-left: 5px;
      path {
        fill: $gray-dark;
      }
    }
  }

  // Align descriptive text with title: 10px swatch + 8px margin
  .dmp-menu-item-info {
    padding-left: 18px;
  }

  .dmp-dd-menu {
    max-height: 400px;
  }
  .dmp-ellipsis {
    display: flex;
    align-items: center;
  }
}

.toolbar-section {
  .dmp-btn.dmp-btn-link.btn {
    &.no-action-status {
      text-decoration: none;
      margin-left: 8px;
    }
  }
}
