/*
  DMP COMPONENT: SWATCH
*/

$dmp-swatch: '#{$dmp}-swatch';

.#{$dmp-swatch} {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  margin-right: 7px;
}
