.siggy {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #d4dbe4; // $gray-3
  width: 100%;
  height: $sig-height;
  font-size: 1.4rem;
  margin: 10px 0 5px 0;

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    .sign-text-prompt {
      display: flex;
      align-items: center;
      @extend .font_sans;
      color: #8795a5; // $gray-2
      font-weight: 500;
    }

    .icon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      path {
        fill: rgba($discuss, 0.8);
      }
    }
  }

  &.signing {
    .siggy-content .sign-text-prompt {
      color: $discuss;
    }
  }

  &.waiting,
  &.ready-check:not(.signed) {
    &:hover {
      cursor: default;
    }
  }

  &.signing:not(.ready-check),
  &.signable {
    .sign-text-prompt {
      cursor: pointer;
      flex: 1;
      color: $discuss;
      @extend .font_sans;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
        text-decoration-color: $discuss;
      }
    }
  }

  &.signed .siggy-content {
    align-items: flex-start;
  }

  // Native deals (as opposed to pdf) have light bg applied to different states to frame the label
  &.native {
    background: $black2;
    &.signing:not(.ready-check) {
      background: rgba($discuss, 0.05);
    }
    &.signed {
      background: none;
    }
  }

  .signature {
    display: block;
    max-height: 50px;
    max-width: 100%;
  }

  // Signautre Menu
  .signature-menu {
    // Placing the actions button above the Siggy field
    top: -30px;
    right: 0;
    position: absolute;

    .dmp-dd-menu > li > a {
      overflow-wrap: break-word;
    }

    // Because this is nested inside source sections, we need to override to get correct menu font style
    /* ul {
      width: 180px;
      li a {
        padding: 3px 15px;
        @extend .font_size_s;
        @extend .font_sans;
        .swap-email { color: $black40; }
      }
      li.dropdown-header {
        @extend .font_sans;
        padding: 5px 15px;
        font-weight: 600;
      }
    } */
  }
}
