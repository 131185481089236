.top-bar {
  @include mediaquery-medium {
    position: fixed;
    height: $top-bar-height;
    z-index: $layer-topbar;
    width: 100%;
    // background-color: $outlaw-dark;
    background-color: $white;
    border-bottom: 1px solid $black10;
    top: 0;
    left: 0;
    color: $black80;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 3px solid $black80;
    padding: 5px 15px;

    .user-menu {
      margin-top: 0;
    }
    .logo {
      height: 19px;
    }

    //in DealView mode (which includes overview, contract and editor)
    //we want a slimmer bar with no team selector and smaller logo
    &.mode-dealview {
      height: $top-bar-height-slim;
      .logo {
        height: 18px;
      }
    }
  }

  .hamburger {
    position: fixed;
    top: 20px;
    left: 15px;
    cursor: pointer;
    z-index: $layer-mobile-nav;

    &.dark-mode {
      path {
        fill: $gray-2;
      }
    }
  }

  .dmp-mobile-nav {
    ul {
      margin-left: 24px;
      margin-top: 22px;
      li {
        padding-bottom: 16px;
        @include font-sizing(large);
        a {
          font-weight: 600;
          color: $outlaw-dark;
          cursor: default;
        }

        //sign-in link when in guest mode
        .sign-in,
        .sign-up {
          color: $outlaw !important;
          text-decoration: underline;
        }

        &:last-child {
          //logout
          a {
            color: rgba($outlaw-dark, 0.5);
          }
        }
      }
    }
    .logo {
      position: absolute;
      bottom: 24px;
      left: 22px;
      height: 28px;
    }
  }
}

// Pseudo 'search' button which really brings up the search modal (overlay)
.omnisearch {
  cursor: pointer;
  height: 40px;
  min-width: 40px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  // On mobile, the search icon only shows on the Contracts page
  // so this is a hack to get it to show up next to the "New Contract" button
  // which is a fixed 120px width
  top: 10px;
  left: calc(100vw - 170px);

  //mobile hover
  &:hover {
    background: $black10;
  }

  svg {
    //Magnifying icon
    width: 20px;
    flex-shrink: 0;
    path {
      fill: $black80;
    }
  }

  span {
    // placeholder text: Search by keywords, parties, etc.
    font-weight: 500;
    padding-left: 8px;
    padding-right: 6px;
    color: $black40;
    flex-shrink: 0;
    display: none;
  }
  // Desktop styling (non-mobile css)
  @include mediaquery-medium {
    left: 50%;
    top: 14px;
    position: absolute;
    transform: translateX(-50%); //center this div
    width: 300px;
    border: 1px solid $black10;
    background: $white;
    justify-content: flex-start;
    padding-left: 15px;
    padding-right: 15px;

    svg {
      width: 15px;
      path {
        fill: $black40;
      }
    }
    span {
      display: block;
    }

    //desktop hover status
    &:hover {
      background: $white;
      border-color: $black20;
      svg {
        path {
          fill: $black80;
        }
      }
      span {
        color: $black80;
      }
    }
  }
}
