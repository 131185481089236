/*
  Color Picker overrides
*/

.color-picker {
  position: relative;
  .sample {
    display: block;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 2px;
  }
  .picker {
    @extend .bigshadow;
    position: absolute;
    top: 40px;
  }

  .dmp-dd {
    width: 100%;
    .dropdown-toggle {
      width: 100%;
      .dmp-ellipsis {
        display: flex;
        align-items: center;
      }
    }
    .sample {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
    ul {
      padding: 0;
      li {
        padding: 0;
      }
      .flexbox-fix input {
        margin-top: 3px;
      }
    }
  }

  &.style-github {
    .github-picker {
      width: 186px !important;
      border: none !important;
    }
  }

  &:not(.editable-input) {
    .compact-picker .flexbox-fix {
      display: none !important;
    }
  }
}
