//dashboard vars
$sidebar-width: 60px;
$deal-filters-width: 200px;
$wrapper-margin-width: 30px;
$max-large-dash-width: 1024px;

.empty-state {
  border-radius: 4px;
  background: $white;
  padding: 20px;
  text-align: center;
  @extend .papershadow;
  @extend .font_sans;
  text-align: center;

  white-space: normal;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  .contents {
    width: 90%;
    @include mediaquery-medium {
      width: 70%;
    }

    .dmp-btn {
      color: $black40;
    }
  }
}

.dashboard {
  position: absolute;
  background: $black2;
  top: 0;
  left: 0;
  right: 0;
  // bottom: 0;
  display: flex;
  flex-direction: row;

  .module-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid $black3;
    span {
      flex: 1;
      font-weight: 600;
      color: $black60;
    }
    a {
      font-weight: 300;
      cursor: pointer;
      color: $black40;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#primary-nav {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid $black10;

  a {
    svg {
      path {
        fill: $white;
      }
    }
  }

  .bottom {
    margin-top: auto;
  }

  &.anonymous .sign-in {
    color: $outlaw;
    cursor: pointer;
    text-decoration: underline;
  }
  a.disabled {
    cursor: not-allowed;
    svg {
      opacity: 0.25;
    }
    &:hover {
      svg {
        opacity: 0.25;
      }
    }
  }

  // Nav on medium +
  @include mediaquery-medium {
    position: sticky;
    top: $top-bar-height;
    bottom: 0;
    width: $sidebar-width;
    background-color: darken($outlaw-dark, 5%);
    height: calc(100vh - #{$top-bar-height} + 1px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    min-height: 420px;

    div {
      &:last-child {
        padding-bottom: 20px;
      }
    }

    a {
      width: $sidebar-width;
      height: $sidebar-width;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      svg {
        opacity: 0.5;
      }
      &.active {
        background: $outlaw-dark;
        svg {
          opacity: 1;
        }
        &::after {
          content: '';
          width: 3px;
          height: inherit;
          background-color: $outlaw;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
  }
}

.main {
  // width: 100%;
  // position: absolute;
  flex: 1;
  margin-top: $mobile-nav-height;
  @include mediaquery-medium {
    margin-top: $top-bar-height;
    left: $sidebar-width + $wrapper-margin-width;
    width: calc(100% - #{$sidebar-width} - #{$wrapper-margin-width} * 2);
  }
  .wrapper {
    width: inherit;
    margin: 0 auto;
    display: block;
    @include mediaquery-medium {
      width: 100%;
      max-width: 768px;
      padding: 0 30px;
      // This is necessary to avoid the side nav overlapping with page content
      // The contracts page has a collapsible filter component which accounts for spacing
      // So only apply additional padding on other dashboard pages
      &:not(.contracts) {
        padding-left: $sidebar-width + 30px;
      }
    }
    @include mediaquery-large {
      max-width: $max-large-dash-width;
    }
  }

  &.contracts-list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: calc(100vh - #{$top-bar-height});
    left: 0;
    width: 100%;

    @include mediaquery-medium {
      left: 80px + 201px;
      width: calc(100% - #{$sidebar-width} - #{$deal-filters-width});
    }
  }
  .title-bar {
    width: 100%;
    z-index: $layer-deal-steps;
    background: $white;
    border-bottom: 1px solid $black10;
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-left: 50px; //make room for hamburger icon
    padding-right: 15px; //push action button away from edge

    @include mediaquery-medium {
      padding: 0;
      z-index: auto;
      height: 100px;
      position: static;
      width: 100%;
      //max-width: 768px;
      background: none;
      border-bottom: 0;
    }
    @include mediaquery-large {
      //max-width: $max-large-dash-width;
    }

    h1 {
      font-size: 1.5rem;
      // This gets the page title properly vertically centered with hamburger menu
      line-height: initial;
      font-weight: 600;
      margin: 0;

      @include mediaquery-medium {
        font-size: 3.2rem;
        font-weight: 100;
        padding-left: 0;
        flex: 1;
        line-height: 1.2; //reset
      }
    }

    h1,
    & > .actions {
      @include mediaquery-medium {
        flex: auto;
        width: 150px;
      }
    }

    & > .actions {
      //[search button] and [new deal|template] //search only appears /contracts
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 10px;
      @include mediaquery-medium {
        position: static;
        right: auto;
      }

      // note, this is only rendered on desktop
      .import {
        display: flex;
        align-items: center;
        margin-right: 10px;
        padding-left: 9px;
        svg {
          width: 15px;
          height: 15px;
        }
        .title {
          margin-left: 5px;
        }
      }
    }
  }
}

//styles used below
.bottom_border_style {
  //reusable style
  border-bottom: 1px solid $black3;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.add_ellipses {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//e.g., 250 results with...
.results {
  font-size: 1.2rem;
  color: $black80;
  padding-right: 5px;
  font-weight: 600;
  display: inline;
  flex-shrink: 0;
}

.tag-wrapper {
  // width: inherit;
  // // padding: 20px 20px 20px 20px;
  // // @include mediaquery-medium {
  // //   padding: 0 0 20px 0;
  // // }

  //e.g., [Party: Joe’s Coffee (x)]
  .tags {
    display: inline;

    .clear-tags {
      //make tags  and clear tag consistent
      display: inline-block;
      position: relative;
      margin-right: 3px;
      padding: 3px 5px;
      border-radius: 2px;
      cursor: pointer;
      margin-bottom: 2px; //some space for when they wrap to 2 lines
      border: 1px solid $black10;
    }

    .clear-tags {
      font-size: 0.9rem;
      line-height: 1.1rem;
      top: -1px;
      font-weight: 700;
      text-transform: uppercase;
      color: $black40;
      background-color: $black3;
      border-color: $black10;
      &:hover {
        color: $black80;
        background-color: $black10;
      }
    }
  }
}

//page count is used in 2 x places
.page-count {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  justify-content: space-between;

  button {
    height: auto;
    padding: 0; //reset
    margin: 0;
    font-size: 1.2rem;
    svg {
      path {
        fill: $black40;
      }
    }
    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
  }
  .status {
    height: auto;
    display: inline-flex;
  }
  span {
    //the slash "/" between numbers
    padding: 0 5px;
    color: $black40;
  }
}

.deal-listing,
.theme-listing {
  //div contains # of results and filtered tags
  &:not(.theme-listing) {
    min-height: 600px;
  }

  .archived-results {
    @include mediaquery-medium {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span {
        margin: 0 5px;
      }
      margin-top: 20px;
    }
    border-radius: 3px;
    @extend .papershadow;
    text-align: center;
    padding: 20px;
    background: white;
    font-size: 1.3rem;
    font-weight: 400;
    svg {
      //magnifying icon
      width: 20px;
      path {
        fill: $black20;
      }
    }
    a {
      color: $outlaw;
      display: block;
      @include mediaquery-medium {
        display: inline;
      }
    }
  }

  .displaying-results {
    font-size: 1.2rem;
    color: $black40;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      padding: 3px 5px;
      line-height: 1.2rem;
      height: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .template-title {
    em {
      font-style: normal;
      color: $gray-2;
      padding-left: 10px;
    }
  }
}

.statusLabel {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  padding: 3px 4px;
  display: block;
  min-width: 90px;
  max-width: 90px;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;

  border-radius: 2px; //$statusLabel-height/2;

  letter-spacing: 0.3px;
  line-height: initial;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.3);

  @mixin statusLabel($color) {
    color: $white;
    background-color: $color;
  }

  &.setup {
    @include statusLabel($black30);
  } // //GRAY
  &.todo {
    @include statusLabel($black30);
  } // GRAY (owner)
  &.draft {
    @include statusLabel($pending);
  } //ORANGE
  &.review {
    @include statusLabel($discuss);
  } // BLUE (recipient)
  &.sign {
    @include statusLabel($outlaw);
  } //PURPLE (owner)
  &.signing {
    @include statusLabel($outlaw);
  } //PURPLE (recipient)
  &.signed {
    @include statusLabel($done);
  } //GREEN
  &.done {
    @include statusLabel($done);
  } //GREEN

  &.template-draft {
    @include statusLabel($black20);
  }

  //for templates
  &.private {
    @include statusLabel($pending);
  } // ORANGE
  &.template-public,
  &.template-active {
    @include statusLabel($done);
  } //GREEN
  //template in omni-search
  &.template {
    @include statusLabel($outlaw-dark);
  } //BLACK

  //for ReadyCheck statuses
  &.cancelled {
    @include statusLabel($black20);
  } //GRAY
  &.passed {
    @include statusLabel($done);
  } // DONE
  &.failed {
    @include statusLabel($alert);
  } //RED
  &.inactive {
    @include statusLabel($black20);
  } //GRAY

  &.new {
    border: 1px solid $black80;
  }

  //for contract-tags (showing up in omnisearch only)
  &.contract-tag {
    //this one breaks the convention a bit, its using an outline style
    border: 1px solid $black20;
    color: $black40;
    padding-left: 15px;
    background: $white;
    &:after {
      // "tag" icon, similar to deal-tags but sizes are bigger
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 7px;
      height: 7px;
      background: $black20;
      clip-path: polygon(0 55%, 55% 0, 100% 0, 100% 50%, 50% 100%);
      -webkit-clip-path: polygon(0 55%, 55% 0, 100% 0, 100% 50%, 50% 100%);
    }
  }
}

.rounded-style {
  border-radius: 3px;
  @extend .papershadow;
  overflow: hidden;
}

.welcome {
  //ensure there's enough space below articles list to not conflict with CRM
  padding-bottom: 90px;

  .welcome-text {
    display: block;
    text-align: center;
    padding: 0px 30px 30px 30px;
    h1 {
      font-size: 3.6rem;
      margin: 0;
    }
    p {
      font-size: 1.6rem;
    }
    @include mediaquery-medium {
      padding-bottom: 60px;
    }
  }

  .dashboard-feed {
    display: flex;
    flex-direction: column;
    @include mediaquery-medium {
      flex-direction: row;
    }
    .dashboard-left {
      flex-basis: 100%;
      padding: 20px 20px 0 20px;
      @include mediaquery-medium {
        flex-basis: 66.66%;
        padding: 0;
        padding-right: 20px;
        // adding additional tweaks
        .active-deals,
        .archived-deals {
          .deal-listing {
            .owner {
              display: none;
            }
            .modified {
              text-align: right;
            }
          }
        }
      }
    }
    .dashboard-right {
      flex-basis: 100%;
      padding: 0px 20px 0 20px;
      @include mediaquery-medium {
        flex-basis: 33.33%;
        padding: 0;
        padding-left: 20px;
      }
    }
  }
}

.slider-outer {
  background: $white;
  @extend .papershadow;
  border-radius: 3px;
  padding: 0;
  margin-bottom: 30px;
  @include mediaquery-medium {
    margin-bottom: 0;
  }

  .slick-slider {
    margin: 0 auto;
    // max-width: 260px;

    // @include mediaquery-small {
    //   max-width: 600px;
    // }
    @include mediaquery-medium {
      height: 420px;
    }
    @include mediaquery-large {
      height: 540px;
    }
    @include mediaquery-xlarge {
      height: 600px;
    }
    // @include mediaquery-large {
    //   max-width: 520px;
    // }
    // margin-right: 30px;
    .slick-slide {
      span {
        font-size: 1.6rem;
        color: $black60;
      }
      img {
        margin: 0 auto;
        width: 100%;
        //margin-bottom: 10px;
      }
      .info {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px 20px;
      }
      button {
        background-color: red;
      }
    }
    .slick-dots {
      margin: 0;
      bottom: 20px;
    }
    // .slick-prev,
    // .slick-next {
    //   width: 30px;
    //   height: 30px;
    // }
    .slick-prev {
      left: 30px;
      z-index: 5;
    }
    .slick-next {
      right: 30px;
    }

    .slick-prev:before,
    .slick-next:before {
      color: $black20;
      font-size: 30px;
    }
  }
}

.active-deals,
.archived-deals {
  .deal-listing {
    min-height: auto;
  }

  .table-row:last-child {
    @include mediaquery-medium {
      border-bottom: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.deal-stats {
  .by-status {
    display: flex;
    flex-direction: row;
  }

  .stat {
    flex: 1;
    padding-top: 15px;
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .statusLabel {
      cursor: help;
    }
    &:first-child {
      border-bottom-left-radius: 3px;
    }
    &:last-child {
      border-bottom-right-radius: 3px;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 8px;

      a {
        font-size: 3.2rem;
        line-height: 3.2rem;
        font-weight: 300;
      }
    }
  }
}

.onboarding {
  border-radius: 4px;
  margin: 30px;
  background: $white;
  padding: 20px;
  text-align: center;

  @extend .papershadow;

  @include mediaquery-medium {
    margin: 0 auto 30px auto;
  }

  .onboarding-header {
    padding-bottom: 20px;
    text-align: center;
    p {
      font-size: 1.6rem;
    }
  }

  .onboarding-steps {
    display: flex;
    flex-direction: column;
    @include mediaquery-medium {
      flex-direction: row;
    }

    .step {
      flex: 1;
      display: flex;
      padding: 20px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      @include mediaquery-medium {
        min-height: 340px; //picking a height here based on 2 lines of copy
      }

      .top {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      .bottom {
      }

      .step-image {
        //img
        width: 100px;
        height: 100px;
        @include mediaquery-large {
          width: 120px;
          height: 120px;
        }
      }

      .step-number {
        //span
        display: block;
        padding-top: 20px;
        font-weight: 700;
        font-size: 1.2rem;
        color: $black40;
      }

      .step-title {
        font-weight: 700;
        font-size: 1.6rem;
        margin: 5px auto 10px;
        color: $black90;
      }

      .step-instructions {
        color: $black60;
        font-size: 1.4rem;
      }

      .check {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        border: 2px solid $outlaw;
        padding: 8px 0 0 2px;
        svg path {
          stroke: $outlaw;
        }
      }

      .step-action {
        position: relative;
        .loader {
          position: absolute;
          margin: 0;
          width: 20px;
          top: 6px;
          right: -30px;
        }
      }

      &.complete {
        .step-number,
        .step-title,
        .step-instructions {
          color: $black20 !important;
        }
      }
    }
  }
}

.team-admin {
  .panel-tabs {
    margin-bottom: 20px;
  }
}

.alert-failed-invites {
  @media only screen and (max-width: $breakpoint-tablet) {
    margin: 10px;
  }

  .close-failed-invites-alert {
    margin-top: -2px;
    color: red;
    font-weight: 600;
    opacity: 0.8;
  }
}

.alert-trash {
  @media only screen and (max-width: $breakpoint-tablet) {
    margin: 10px;
  }

  .close-trash-alert {
    margin-top: -2px;
    color: $color-orange;
    font-weight: 600;
    opacity: 0.8;
  }
}
