//font-family
.font_sans {
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.font_serif_app {
  // font-family: "ff-tisa-web-pro", serif;
  // font-family: "droid-serif",serif;
  // font-family: "adelle",serif;
  font-family: 'Georgia', serif;
}
.font_serif_static {
  // font-family: "ff-tisa-web-pro", serif;
  // font-family: "droid-serif",serif;
  font-family: 'adelle', serif;
}
.font_script {
  font-family: 'kinescope', cursive;
}
.font_fixed {
  font-family: 'Courier', courier;
}

//trialing semantic markup in section-editor first,
//then integrate back into main app

.reset_all {
  padding: 0;
  margin: 0;
}
.margin_auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.margin_bottom_small {
  margin-bottom: 10px;
}
.margin_bottom_med {
  margin-bottom: 20px;
}
.margin_bottom_large {
  margin-bottom: 40px;
}

.margin_top_small {
  margin-bottom: 10px;
}
.margin_top_med {
  margin-bottom: 20px;
}
.margin_top_large {
  margin-bottom: 40px;
}

//font-sizes (keep it at 7)
.font_size_xxxl {
  font-size: 30px;
}
.font_size_xxl {
  font-size: 24px;
}
.font_size_xl {
  font-size: 20px;
}
.font_size_l {
  font-size: 18px;
}
.font_size_m {
  font-size: 16px;
}
.font_size_s {
  font-size: 14px;
}
.font_size_xs {
  font-size: 13px;
}
.font_size_xxs {
  font-size: 11.5px;
}

.txt_headline_1 {
  @extend .font_size_xxxl;
  @extend .font_sans;
  color: $black70;
  font-weight: 400;
  line-height: 1;
  display: block;
  letter-spacing: 0;
}
.txt_headline_2 {
  @extend .font_size_xxl;
  @extend .font_sans;
  color: $black80;
  font-weight: 400;
  line-height: 1;
  display: block;
  letter-spacing: 0;
}
.txt_headline_3 {
  @extend .font_size_xl;
  @extend .font_sans;
  color: $black80;
  font-weight: 400;
  line-height: 1;
  display: block;
  letter-spacing: 0;
}

.txt_headline_6 {
  @extend .font_size_xxs;
  @extend .font_sans;
  // text-transform: uppercase;
  // letter-spacing: 0.09rem;
  font-weight: 500;
  color: $black60;
}
.txt_body {
  @extend .font_size_s;
  @extend .font_sans;
  font-weight: 400;
  display: block;
  line-height: 1.6;
  letter-spacing: 0;
  color: $black60;
}

.txt_meta {
  @extend .font_size_xs;
  @extend .font_sans;
  color: $black40;
  font-weight: 400;
  line-height: 1.4;
}

.txt_link {
  color: $black80;
  font-weight: 500;
}

.circle-x {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  padding: 2px 0 0 1px;
  font-size: 0.9rem;
}

//THINGS TO REMOVE EVENTUALLY

// style used for deal list AND account pages
@mixin listing-row-style {
  display: block;
  border-top: 1px solid $black10;
  // background: $black1;
  color: $black60;
  margin-bottom: 4px;
  position: relative;
  padding: 16px 16px 16px 20px;
  @include mediaquery-medium {
    padding: 20px 24px 20px 24px;
  }
}

.papershadow {
  box-shadow: 0 1px 2px rgba($black100, 0.2);
}
