/*
  DMP COMPONENT: KEY
*/

$dmp-key: '#{$dmp}-key';

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.#{$dmp-key} {
  display: flex;
  flex-direction: row;
  align-content: center;

  line-height: 1em;
  font-size: 1.2rem;
  font-weight: 500;
  color: #8c98a5; // $gray-2

  // Key
  .#{$dmp-key}-key {
    border-radius: 2px;
    border: 1px solid #d9dee4; // $gray-4
    font-weight: 600;
    padding: 2px 5px;
  }

  // Label
  .#{$dmp-key}-label {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
}
