/*
  DMP COMPONENT: CARD
*/

$dmp-card: '#{$dmp}-card';

.#{$dmp-card} {
  background: $white;
  @extend .papershadow;
  margin-bottom: 30px;
  border-radius: 3px;
  padding: 30px;

  &-condensed {
    padding: 15px;
    &.#{$dmp-card}-header {
      padding: 0 15px;
    }
  }

  &-no-padding {
    padding: 0;
    &.#{$dmp-card}-header {
      padding: 0 30px;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    min-height: 30px;
    padding-bottom: 10px;
  }

  &-title {
    flex: 1;
    max-width: 100%;
    font-size: 1.8rem;
    font-weight: 500;

    a {
      display: inline-block;
      color: $black80;

      &:hover {
        text-decoration: underline;
        color: $black80;
      }
    }

    &-sub {
      color: $black40;
      font-size: 1.3rem;
      font-weight: 400;

      a {
        text-decoration: underline;
        color: $black40;
      }

      svg {
        height: 15px;
        width: 15px;
        vertical-align: text-top;

        path {
          fill: $black40;
        }
      }
    }
  }

  &-extra {
    a {
      color: $black40;
      font-weight: 300;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/*
  Card List
*/
.#{$dmp-card}-list {
  &-item {
    border-bottom: 1px solid $gray-3;
    padding: 15px 20px;

    &:last-child {
      border-bottom: 0;
    }
  }
}
