/*
  DMP COMPONENT: FORM
*/

$dmp-form: '#{$dmp}-form';

.#{$dmp-form} {
  /*
    Make sure that button and dropdowns also tak full width
    when they are displayed in a form
  */
  .form-group {
    & > * {
      flex: 1;
    }
  }
}

.expandable-text-input {
  height: 30px;
  min-width: 236px;
  min-height: 30px;
  max-width: 236px;
  max-height: 200px;
}

.expandable-other-input {
  @extend .expandable-text-input;
  height: 45px;
  min-height: 45px;
}

.expandable-panel-input {
  @extend .expandable-text-input;
  min-width: 275px;
  max-width: 275px;
}

//textfield
// Putting this here for now...
.form-control {
  &[disabled] {
    background: $black2;
    color: $gray-2;
    box-shadow: none;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
    // box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($discuss,.6);
    // box-shadow: 0 0 0px 2px rgba($black20,.5);
    box-shadow: 0 0 0 2px #f7f9fc;
  }

  &.expandable-input {
    @extend .expandable-other-input;
  }
}

input.form-control {
  @extend .font_sans;
  font-weight: 400;
  &[readonly] {
    pointer-events: none;
    background: rgba($black10, 0.5);
    color: $black60;
  }
}
