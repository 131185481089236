/*
  DMP COMPONENT: DATA TABLE
*/

$dmp-data-table: '#{$dmp}-data-table';

.#{$dmp-data-table} {
  .-loading {
    // Account for paging
    margin-top: 40px;
    margin-bottom: 40px;
    .-no-header {
      margin-top: 0;
    }
    .-no-footer {
      margin-bottom: 0;
    }

    .dmp-loader {
      margin-right: 10px;
    }
  }

  // Non-scrolling table
  &-no-scroll {
    .ReactTable {
      .rt-table {
        overflow: visible;
        .rt-tbody {
          overflow: inherit;
        }
      }
    }
  }

  // Fix dmp-checkbox height for .th-selection
  .ReactTable {
    .rt-tr {
      .rt-th.th-selection,
      .rt-td.col-selection {
        .#{$dmp-checkbox} {
          height: 20px;
        }
      }
    }
  }

  // Clickable row table
  &-clickable {
    .ReactTable {
      .rt-tbody .rt-tr a.rt-td:hover {
        text-decoration: none;
      }
      .rt-tbody .rt-tr:hover {
        .rt-td {
          background-color: $black2;
          color: $black100;
        }
      }
    }
  }

  // White background  dropshadow styled table
  &-dropshadow {
    .rt-table {
      @extend .papershadow;
      border-radius: $rt-border-radius;

      .rt-thead {
        .rt-tr {
          .rt-th {
            &:first-child {
              border-top-left-radius: $rt-border-radius;
            }
            &:last-child {
              border-top-right-radius: $rt-border-radius;
            }
          }
        }
      }

      .rt-tbody {
        .rt-tr-group {
          &:last-child {
            //round out edges of last row
            .rt-td {
              &:first-child {
                border-bottom-left-radius: $rt-border-radius;
              }
              &:last-child {
                border-bottom-right-radius: $rt-border-radius;
              }
            }
          }
        }
      }
    }
  }

  // Style: List type has no column border
  &-style-list {
    .ReactTable {
      .rt-thead .rt-tr {
        .rt-th,
        .rt-td {
          border-right: 0;

          &:last-child {
            text-align: right;
          }
        }
      }

      .rt-tbody,
      .rt-tfoot {
        border-top: 1px solid $black3;
        .rt-tr {
          .rt-td {
            border-right: 0;

            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

// Pager component now included in DataTablePagination
.data-table-pager {
  padding: 10px 20px 0px 20px;
  @include mediaquery-medium {
    padding: 10px 0px 10px 0px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
