/*
  PAGE : INVITATION
*/

$invitepage-max-width: 920px;

.page-invitation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mediaquery-medium {
    align-items: center;
  }
  height: 100vh;
  background-color: $white;
  width: 100%;

  .page-content {
    width: 100%;
    max-width: $invitepage-max-width;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include mediaquery-medium {
      padding-left: 40px;
      padding-right: 40px;
      min-height: 500px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    & > div {
      max-width: calc($invitepage-max-width / 2);
      margin-left: 20px;
      margin-right: 20px;
      @include mediaquery-medium {
        flex: 2;
        margin-left: 0px;
        margin-right: 0px;
      }
      &:first-child {
        @include mediaquery-medium {
          flex: 1;
          padding-right: 60px;
        }
      }
    }
  }

  .invitation-info {
    text-align: center;
    @include mediaquery-medium {
      text-align: left;
    }

    h2 {
      margin-top: 0;
    }

    .team-logo {
      margin-top: 40px;
      max-width: 100px;
      margin-bottom: 20px;
    }

    & > p {
      color: #616485;
      font-size: 1.8rem;
      margin-top: 40px;
      @include mediaquery-medium {
        margin-top: 0;
      }
    }

    .invitation-by {
      p {
        color: #616485;
      }
      margin-bottom: 30px;
      @include mediaquery-medium {
        margin-bottom: 0;
      }
    }
  }

  .invitation-info-bundle {
    text-align: left;

    h4 {
      margin-top: 0;
    }

    .team-logo {
      max-width: 100px;
      margin-bottom: 20px;
      margin-top: 40px;
      @include mediaquery-medium {
        margin-top: 0;
      }
    }

    .document {
      display: flex;
      align-items: center;
      padding-bottom: 16px;

      & > p {
        margin: 0;
        padding-left: 8px;
      }
    }

    .invitation-by {
      p {
        color: #616485;
      }
      margin-bottom: 30px;
      @include mediaquery-medium {
        margin-bottom: 0;
      }
    }
  }

  .card-continue {
    // specific card style tuning
    border: 1px solid $black10;
    padding: 30px 30px;
    @include mediaquery-medium {
      padding: 30px 40px;
    }

    width: 100%;
    text-align: center;

    h3 {
      font-size: 2.8rem;
      font-weight: 400;
      letter-spacing: -0.72px;
      margin-top: 0;
    }

    .info {
      color: $outlaw-dark;
    }
  }
}

#page-footer {
  width: 100%;
  background-color: $black3;

  .footer-content {
    margin: 0 auto;
    padding: 20px;
    max-width: $invitepage-max-width;
    @include mediaquery-medium {
      padding: 30px 20px;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  .logo {
    width: 70px;
    opacity: 0.2;
    display: block;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    @include mediaquery-medium {
      margin-bottom: 0;
      margin-right: 30px;
    }
  }
  div {
    color: $black30;
    text-align: center;
    @include mediaquery-medium {
      text-align: left;
    }
    a {
      color: $black30;
    }
  }
}
