.lens-sidebar {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background-color: rgb(255, 255, 255);

  //Clause pannel tabs
  .filter-bar {
    .panel-tabs {
      .dmp-btn-link {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .spacer {
    flex: 1;
  }

  .dmp-alert-danger {
    .dmp-alert-content {
      .alert-actions {
        display: flex;

        a {
          font-weight: 100;
        }

        .cancel {
          margin-left: auto;
          margin-right: 5px;
          color: $gray-0;
        }
      }
    }
  }

  //Container for all lens sidebar blocks
  .lens-list {
    .dmp-alert-danger {
      margin-top: 10px !important;
    }
    //Lens Blocks
    .lens-block {
      //Display state
      .display-block {
        display: flex;

        &.error {
          .lens-title {
            color: $color-red !important;
          }

          .openContent,
          .closeContent {
            svg {
              path {
                fill: $color-red !important;
              }
            }
          }

          .left {
            .condition-icon {
              path {
                fill: $color-red !important;
              }
            }
          }
        }

        .left {
          margin-right: 10px;
          display: flex;
        }

        .right {
          width: 100%;

          .question {
            color: $gray-0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            margin-top: 5px;
            margin-right: 15px;
          }

          .lens-topline {
            display: flex;
            align-items: center;
            height: 15px;

            .lens-title {
              color: $gray-0;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 15px;
            }

            .lens-advanced-title,
            .lens-clause-title {
              cursor: pointer;
              text-decoration: underline;
              /*&:hover {
                color: $gray-1;
              }*/
            }

            .openContent,
            .closeContent {
              margin-left: auto;
              svg {
                margin-top: 1px;
              }
            }
          }

          .lens-group {
            margin-top: 10px;
            color: $gray-1;
            font-size: 13px;
            font-weight: 600;
            line-height: 16px;
            text-decoration-line: underline;
          }

          .lens-filter {
            cursor: pointer;
            margin-top: 10px;
            display: flex;
            color: $gray-1;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            text-decoration-line: underline;

            .filter-display-label {
              .dmp-i {
                vertical-align: middle;
                margin-top: -2px;
                margin-left: 5px;
              }
            }

            &.add-new {
              color: $outlaw;
              svg {
                margin-right: 5px;
                margin-top: 2.5px;
                path {
                  fill: $outlaw;
                }
              }
            }

            .filter-risk-value {
              margin-left: auto;
              min-width: 15px;
              text-align: center;
            }
          }

          .delete {
            margin-top: 15px;
            width: 15px !important;
            height: 15px !important;
            margin-left: auto;

            path {
              fill: $black20;
            }
            &:hover {
              path {
                fill: $black80;
              }
            }
          }
        }
      }

      //Editing State
      .edit-lens-container {
        &.inline {
          margin-top: 10px;
        }

        .lens-editing-title {
          color: $gray-0;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;

          svg {
            margin-right: 8px;
            vertical-align: middle;
            margin-top: -2px;
          }

          &.error {
            color: $color-red !important;

            svg {
              path {
                fill: $color-red !important;
              }
            }
          }
        }

        .lens-property-block {
          margin-top: 10px;
          padding: 15px 15px 15px 15px;
          background: $black2;
          display: flex;
          flex-direction: column;

          .form-group {
            margin-bottom: 0px !important;
          }

          .lens-editior-title {
            display: flex;
            margin-bottom: 15px;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 15px;
            color: $gray-0;

            .delete-target-range {
              margin-left: auto;
              path {
                fill: $black20;
              }
              &:hover {
                path {
                  fill: $black80;
                }
              }
            }
          }

          .inline-condition {
            display: flex;
            flex-direction: row;
            margin: 0px 0px 15px 20px;

            .form-group {
              margin-bottom: 10px !important;
            }

            .lens-property {
              flex: 1;
              margin: 0px;
            }

            .condition-icon {
              path {
                fill: orange;
              }
            }

            &.hasError {
              border-left: 3px solid $color-red;

              .condition-icon {
                path {
                  fill: $color-red !important;
                }
              }
            }
          }

          .condition-icon {
            margin-right: 5px;
          }

          .lens-property {
            flex: 1;
            margin: 0px 0px 15px 20px;

            &.linking-sections {
              .form-control[disabled] {
                color: #2b3542;
                background-color: rgb(255, 255, 255);
                background-image: none;
                border: 1px solid #d9dee4;
              }
            }

            .lens-topline {
              display: flex;
              line-height: 15px !important;
            }

            .remove {
              margin-left: auto;
              width: 15px !important;
            }

            .lens-property-label {
              color: $gray-1;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 15px;
              margin-bottom: 5px;
              display: flex;
            }

            .variable-options {
              margin-top: 2px;

              .variable-filter {
                .values {
                  margin-top: 10px;
                }
              }

              &.scrollable {
                max-height: 200px;
                overflow-y: auto;
              }

              .condition-option {
                display: inline-block;
                padding: 1px 3px;
                border: 1px solid $black10;
                background: $white;
                font-size: 1.2rem;
                margin-bottom: 5px;

                label {
                  padding-left: 20px;
                }

                &:not(:last-child) {
                  margin-right: 8px;
                }
              }
            }

            .values {
              &.pair {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .and {
                  padding: 0 5px;
                }
              }
            }
          }

          .remove {
            path {
              fill: $black20;
            }
            &:hover {
              path {
                fill: $black80;
              }
            }
          }
        }

        .actions {
          text-align: left;
          display: flex;
          .cancel {
            margin-left: auto;
          }
        }
      }
    }
  }
}
