.version-history {
  $version-history-height: 500px;
  $vh-header-height: 57px; //measured based on margin, should fix this later
  $vh-col-header-height: 40px;
  $vh-body-height: $version-history-height - $vh-header-height - $vh-col-header-height;
  $vh-show-diff-height: 50px;

  //used in a few places
  .version {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    .version-label {
      min-width: 30px;
      .version-number {
        background: $black20;
        color: $white;
        font-size: 1.4rem;
        font-weight: 700;
        padding: 3px 8px;
        text-align: center;
        margin-right: 8px;
        border-radius: 2px;
      }
    }
    .info {
      color: $black40;
      .author {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1;
      }
      .timestamp {
        font-size: 1rem;
        font-weight: 300;
      }
    }

    &.selected {
      border-left-color: $outlaw;
      .version-number {
        background: $black40;
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
  }
  .modal-header {
    border-bottom: 0 !important;
  }

  .version-col-body {
    @extend .papershadow;
    min-height: $vh-body-height;
    max-height: $vh-body-height;
    background: $white;
    border-radius: 3px;
    padding: 15px;
    margin: 20px 20px 0;
    overflow-y: scroll;
  }
  .version-action {
    color: $black80;
    padding: 15px 20px;
    button.restore {
      text-decoration: underline;
      font-weight: 700;
      font-size: 1.3rem;
      font-weight: 600;
      padding: 0;
    }
  }
  .current,
  .compare {
    flex: 1;
    display: flex;
    flex-direction: column;
    // border-right: 1px solid $black10;
  }

  .current {
    background: rgba($outlaw, 0.02);

    .section-header {
      background: $white;

      .version-number {
        background: $outlaw;
      }
    }
  }

  .compare {
    background: $black10;
    .section-header {
      background: $black2;
      > .version .version-number {
        background: $black40;
      }
    }
  }

  .section-header {
    width: 100%;
    height: $vh-col-header-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    padding: 0 20px;
    border-bottom: 1px solid $black10;
    border-top: 1px solid $black10;
  }

  //overrides for text display inside VersionHistory
  .source-section {
    flex: 1;
    display: block;
    padding: 0 !important;
    background: none;
    .source-number {
      display: none;
    }
    .source-text,
    .source-title {
      @extend .font_fixed;
    }
    .variable,
    .variable:hover {
      border-bottom: 1px dotted $black40;
      cursor: auto;
    }
    //default style overrides to make sure sections show up nicely in version history
    .marker {
      display: none;
    }
    &:after {
      display: none;
    }
  }

  .show-diff {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: $vh-show-diff-height;
    align-items: center;
  }
}
