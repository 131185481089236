@import '../global-variables';
$dmp-multi-select: '#{$dmp}-multi-select';

.#{$dmp-multi-select} {
  width: inherit;

  .dropdown-menu {
    min-width: 100%;
    max-width: 100%;
  }

  .#{$dmp-multi-select}-list {
    padding: 5px;
  }
  .#{$dmp-multi-select}-list-item {
    color: $outlaw;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    .header {
      font-size: 12px;
      word-break: break-word;
      margin-right: 5px;
    }
    a svg {
      align-self: center;
      path {
        fill: $outlaw;
      }
    }
  }

  &.expandable-input {
    @extend .expandable-other-input;
  }
}
