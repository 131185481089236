/*
  DMP COMPONENT: LOADER
*/

$dmp-loader: '#{$dmp}-loader';

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.#{$dmp-loader} {
  display: flex;

  &-spinner {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.15em solid #d4dbe4; // $gray-3
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
  }

  // Size
  &-xsmall .#{$dmp-loader}-spinner {
    width: 1rem;
    height: 1rem;
    border-width: 0.08em;
  }
  &-small .#{$dmp-loader}-spinner {
    width: 1.2rem;
    height: 1.2rem;
    border-width: 0.1em;
  }
  &-large .#{$dmp-loader}-spinner {
    width: 2rem;
    height: 2rem;
    border-width: 0.18em;
  }

  &-padding {
    padding: 10px 0;
  }

  /*
    Styles
  */
  &-dark {
    .#{$dmp-loader}-spinner {
      border-color: $gray-1;
      border-right-color: transparent;
    }
  }

  // Position
  &-centered {
    justify-content: center;
    align-items: center;
  }

  // Full: allow taking all the hegiht to create a "full page" loading display
  &-full {
    height: 100%;
  }

  // Inline: Display the loader inline
  &-inline {
    display: inline-flex;
    vertical-align: middle; // mimic button vertical-align prop
    margin: 0 10px;
  }
}
