/*
  Shared login styling
  Components: UpgradeGuestModal, InviteView.
*/

.login-wrapper {
  a {
    cursor: pointer;
  }

  .sso-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    img {
      height: 20px;
    }
    span {
      flex: 1;
    }
  }

  .or {
    margin: 35px auto 0 auto;
    text-align: center;
    position: relative;
    border-top: 1px solid $black10;

    div {
      width: 30px;
      height: 30px;
      font-size: 1.3rem;
      line-height: 23px;
      background-color: white;
      color: $black40;
      font-weight: 600;
      z-index: 1;
      margin-top: -12px;
      margin-left: calc(50% - 12px);
    }
  }

  .form-fields {
    text-align: left;

    .help-block {
      font-size: 1.2rem;
    }

    .btn-primary {
      height: 40px;
    }
  }

  .footer-login {
    text-align: center;
    font-size: 1.3rem;
    margin-top: 18px;
  }

  .login-error {
    margin-top: 20px;
  }
}
