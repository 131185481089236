.payment-section {
  display: flex;
  position: relative;
  color: $black60;
  margin-bottom: 10px;

  &.editing-section {
    padding: 5px 0;
  }

  //custom look and feel for payments
  .editing-payment {
    width: 100%;

    .editing-elements {
      display: flex;
      .payment-type,
      .payment-amount,
      .payment-unit,
      .payment-trigger {
        float: left;
        margin-right: 5px;
      }
      .payment-amount {
        width: 90px;
      }
      .payment-unit {
        width: 110px;
      }
      .payment-trigger {
        width: 140px;
      }
      .custom {
        width: 120px;
      }
    }
  }
  .payment-controls {
    display: flex;
    button {
      margin-left: 3px;
    }
  }
}
