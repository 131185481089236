.total-batch {
  max-width: 1200px !important;
  padding: 0 20px;
  @include mediaquery-medium {
    padding: 0px 0px;
  }

  & > h4 {
    margin: 20px 0;
    @include mediaquery-medium {
      margin: 0px;
    }
  }

  .batch-config {
    display: flex;
    flex-direction: column;
    @include mediaquery-medium {
      align-items: flex-start;
      flex-direction: row;
      margin: 20px 0px;
    }

    .op-selection {
      flex: 1;
      padding: 10px 20px;
      @include mediaquery-medium {
        // max-width: 350px;
        margin-right: 30px;
      }

      .op-alert {
        margin-top: 15px;
        margin-bottom: 10px;
      }

      .op-steps {
        .op-req {
          font-weight: 600;
          color: $black60;
          margin-bottom: 10px;
        }
        ul {
          padding-left: 15px;
          li {
            margin-bottom: 5px;
            list-style-type: disc;
          }
        }
      }
    }

    > :last-child {
      flex: 2;
    }

    .setting-title {
      font-weight: bold;
      flex: 1;
      align-self: flex-start;
      margin: 10px 0;
    }

    .batch-setting {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      padding: 10px 20px;

      .setting-content {
        flex: 2;

        //For switch's span to align with switch
        .#{$dmp-switch} {
          span.chk-over-limit-label {
            vertical-align: bottom;
            margin-left: 0px;
            margin-right: auto;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $black10;
      }

      &.fvda {
        border-bottom: none;
      }

      .fvda-warning {
        margin-top: 10px;
      }
    }

    .template-download {
      margin-top: 10px;
      font-size: 1.2rem;
      color: $black60;

      a {
        display: block;
        margin-top: 10px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    label.generation-mode,
    label.sharing-mode,
    label.date-mode {
      margin-top: 3px;
      margin-right: 15px;
      cursor: pointer;
      input {
        margin-right: 5px;
      }
      span {
        margin-top: 1px;
      }
      font-weight: 300;
    }

    .csv-up {
      border: 1px dashed $black10;
      padding: 10px;
      cursor: pointer;
      &.populated {
        background: $black3;
        border-style: solid;
        cursor: default;
      }
      .hit-area {
        display: flex;
        flex-direction: row;

        .instructions {
          flex: 1;

          .csv-file,
          .drop-csv {
            font-size: 1.3rem;
          }
          .drop-csv {
            text-decoration: underline;
          }
        }

        .btn-clear {
          align-self: center;
        }

        small {
          padding: 0;

          &.error {
            color: $alert;
          }
        }
      }
    }
  }

  .meta-actions {
    display: flex;
    align-items: center;
    margin: 20px 0;

    h4 {
      margin-bottom: 0;
      .status {
        display: block;
        @include mediaquery-medium {
          display: inline;
          margin-left: 10px;
        }
        &.missing {
          color: $pending;
        }
        &.ready {
          color: $discuss;
        }
      }
    }
    .meta-instructions,
    .meta-errors,
    a {
      font-size: 1.2rem;
      color: $black40;
      margin-right: 10px;
      text-align: left;
    }
    a {
      text-decoration: underline;
    }
    .meta-errors {
      color: $alert;
    }
    .spacer {
      flex: 1;
    }

    button {
      margin-left: 10px;
    }
  }

  .file-list {
    width: 100%;
    min-height: 100px;
    margin-bottom: 30px;

    .rt-tr {
      &.missing .rt-td {
        background: rgba($pending, 0.15);
        svg {
          border: 2px solid $pending;
          border-radius: 50%;
          path {
            fill: $pending;
          }
        }
      }

      &.ready {
        svg path {
          fill: $black40;
        }
      }

      &.done {
        svg path {
          fill: $done;
        }
      }

      .rt-td {
        &.process {
          svg,
          img {
            width: 16px;
            height: 16px;
          }
        }

        &.status .statusLabel {
          color: $black80;
          background: $white;
          border: 1px solid $black20;

          &.missing {
            color: $pending;
          }
        }

        &.parties {
          width: auto;
          > div {
            display: flex;
            .party-item {
              min-width: 120px;
            }
            .party-item:first-child {
              margin-right: 20px;
            }
          }
        }
      }

      &.uploading {
        color: $black40;
        .rt-td.status .statusLabel {
          color: $black40;
        }
      }
    }
  }

  &.customer,
  &.sales {
    color: $discuss;
    padding: 20px;
    .cta {
      h4 {
        margin-top: 0;
      }
      padding: 15px 10px 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      border-radius: 5px;
      border: 1px solid rgba($discuss, 0.5);
      background: rgba($discuss, 0.05);
      a,
      a:hover {
        color: $discuss;
        text-decoration: underline;
      }
      button {
        border: 0;
        margin-top: 15px;
        background: $discuss;
      }
    }
  }
}
