.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;

  > * {
    flex: 1 0 0;
  }

  > *.flex-grow {
    flex-grow: 1;
  }

  > *.flex-shrink {
    flex-grow: 0;
  }

  &-center {
    align-items: center;
  }
  &-stretch {
    align-items: stretch;
  }
}

.width-100 {
  width: 100% !important;
}

.padding-top-small {
  padding-top: 0.5rem;
}

.margin-top-small {
  padding-top: 0.5rem;
}

.color-initial {
  color: initial;
}
