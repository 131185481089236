@font-face {
  src: url('/assets/fonts/PlusJakartaSans.ttf') format('truetype');
  font: {
    family: 'Jakarta Sans';
    style: normal;
    weight: normal;
  }
}

@font-face {
  src: url('/assets/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
  font: {
    family: 'Jakarta Sans';
    style: normal;
    weight: bold;
  }
}

@font-face {
  src: url('/assets/fonts/PlusJakartaSans-Italic.ttf') format('truetype');
  font: {
    family: 'Jakarta Sans';
    style: italic;
    weight: normal;
  }
}

@font-face {
  src: url('/assets/fonts/PlusJakartaSans-Bold-Italic.ttf') format('truetype');
  font: {
    family: 'Jakarta Sans';
    style: italic;
    weight: bold;
  }
}
