/*
  PDF EDITOR COMPONENT: POPOVER ELEMENT
*/

$pdfe-popover-element: '#{pdfe}-popover-element';
$gray-3: #d4dbe4;

.#{$pdfe-popover-element} {
  width: auto;
  max-width: 300px;

  // Adjust top-left arrow
  &.dmp-popover-topleft {
    & > .arrow {
      border-width: 5px;
      bottom: -9px;
    }
  }

  .var-selection {
    display: flex;
    align-items: center;

    .title {
      color: $gray-3;
      flex: 1;
      font-weight: 400;
      cursor: default;
    }
  }

  .variable-name {
    line-height: 2em;
    height: 24px;
    padding-right: 12px;
  }

  // Manually define the formatting dropdowns (can standardize later)
  .dmp-dd-toggle,
  .signature-menu {
    min-width: 60px;
    max-width: 100%;
  }

  .assigned-party {
    position: relative;
    display: flex;
    align-items: center;
    color: $gray-3;
    font-size: 1.2rem;
    padding-right: 10px;
    white-space: nowrap;
  }

  .assigned-party,
  .signature-menu {
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      right: 0;
      top: -10px;
      bottom: -10px;
      background: rgba($gray-3, 0.3);
    }
  }

  .signature-menu {
    padding-right: 10px;
    padding-left: 10px;

    // A signature menu will be last child if it's signed (so there's no delete button)
    &:last-child {
      margin-right: 0;
      &::after {
        display: none;
      }
    }
  }

  .btn-actions {
    .dmp-btn {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .dmp-btn-icon:last-child {
      padding-left: 7px;
    }
  }

  .btn-actions {
    max-width: 100%;
    //.dmp-btn:last-child { padding-left: 10px; }
  }

  &-type-signature {
    .dd-field-variable.dropdown {
      display: inline-block;
      width: auto;
      max-width: 130px;
      margin-bottom: 0;
    }
  }
}
