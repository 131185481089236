.pdf-view {
  top: 0px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  // push down margins because the top bar and deal-steps
  // have to use top instead of margin because margin-top throws off the tooltips
  position: relative;
  //padding-top: $top-bar-height-slim + $deal-header-height;
  top: 20px;
  padding-bottom: $deal-header-height;
  @include mediaquery-medium {
    top: $top-bar-height-slim + $deal-header-height;
    padding-bottom: $deal-header-height + $margin-desktop;
  }

  .wrapper-canvas {
    position: relative;
    margin: auto;

    .pages {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .page-drop {
      position: relative;

      .pdf-page {
        @extend .papershadow;
        border: 1px solid $black10;
        margin-bottom: 15px;
      }

      &.edit-mode {
        cursor: crosshair;

        &-variable {
          cursor: url(/assets/svg/cursor-element-variable.svg) 6 6, url(/assets/cur/cursor-element-variable.cur) 6 6,
            crosshair;
        }
        &-signature {
          cursor: url(/assets/svg/cursor-element-signature.svg) 6 6, url(/assets/cur/cursor-element-signature.cur) 6 6,
            crosshair;
        }
      }
    }
  }

  // TODO: accommodate dynamic page size depending on zoom (right now this assumes 150% = 918)
  @include mediaquery-xlarge {
    &.panel-open {
      .wrapper-canvas {
        margin-left: calc((100vw - 918px - 320px)/2);
      }
    }
  }

  .page-text-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    // Important for text selection
    opacity: 0.2;

    // If we add this, text is slightly shifted to the top, it should be this though
    // but I haven't figured out why we really need a non-1 line height :/
    //line-height: 1.0;

    & > span {
      background-color: transparent;
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
    }

    & > span::selection {
      background-color: $discuss;
    }

    &.copying > span {
      color: black;
      //background: transparent;
    }
  }

  .pdf-cursor {
    position: absolute;
    transform: translate(10px, 5px);
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ingest {
    position: fixed;
    top: 80px;
    right: -5px;
    z-index: 1;
    color: $black60 !important;
  }
}
