/*
  DMP COMPONENT: DROPPER
*/

$dmp-dropper: '#{$dmp}-dropper';

.#{$dmp-dropper} {
  position: fixed !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1010;
  pointer-events: none;
  display: flex;
  justify-content: center;
  opacity: 0;
  &.dragging {
    pointer-events: all;
    opacity: 1;
  }
  .overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    justify-content: center;
  }
  .valid-area {
    width: 360px;
    height: 270px;
    border-radius: 4px;
    padding: 10px;
    background: $white;
    margin-top: 300px;

    .dotted {
      pointer-events: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px dashed $black10;
      &.valid {
        border-color: $discuss;
      }
      .icon {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        border: 2px solid $discuss;
        display: flex;
        align-items: center;
        justify-content: center;
        svg path {
          fill: $discuss;
        }
        margin-bottom: 10px;
      }
      .title {
        text-align: center;
        @extend .font_size_xl;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .subtitle {
        text-align: center;
        @extend .font_size_s;
        color: $black20;
      }
    }
  }
}
