/*
  DMP COMPONENT: CHECKBOX
*/

$dmp-checkbox: '#{$dmp}-checkbox';

.#{$dmp-checkbox} {
  cursor: pointer;

  label {
    cursor: pointer;
    // bootstrap overrides
    font-weight: normal;
    margin: 0;

    position: relative;
    display: inline-block;

    // minimum height for no-label checkboxes
    min-height: 20px;

    // 16px width of fake checkbox + 6px distance between fake checkbox and text
    padding-left: 22px;
    padding-top: 1px;

    &:hover {
      &::before {
        background-color: $black3;
      }
    }
  }

  input[type='checkbox'] {
    // hide the native html checkbox itself
    opacity: 0;
    position: absolute;
    margin: 0;
    left: 1px;
    top: 3px; //align hidden checkbox in same location as pseudo classes

    // common styles for box and mark
    + span::before,
    + span::after {
      position: absolute;
      content: '';
      //needed for the line-height to take effect
      display: inline-block;
      z-index: 1;
    }

    //styling for box
    + span::before {
      height: 13px;
      width: 13px;
      border: 1px solid $black20;
      background: $white;
      border-radius: 3px;
      left: 1px;
      top: 4px;
      // box-shadow: inset 0 1px 1px rgba($black100,.2);
    }

    // styling for checkmark
    + span::after {
      display: none;
      height: 5px;
      width: 8px;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(-45deg) scale(0.88);
      left: 3px;
      top: 7px;
    }

    // When checked, unhide mark and give box solid fill
    &:checked {
      + span::after {
        display: inline-block;
      }
      + span::before {
        background: $outlaw-lightgrey;
        border-color: $outlaw-lightgrey;
      }
    }

    // when the real (invisible) element has focus, create custom glow on pseudo element
    &:focus + span::before {
      box-shadow: 0 0 0px 2px rgba($black20, 0.5);
    }
  }

  &-disabled {
    cursor: not-allowed;
    label {
      cursor: not-allowed;
      input[type='checkbox'] + span::before {
        background: $black10 !important;
        border-color: $black10 !important;
      }
      color: $black40 !important;
    }
  }

  &-bold {
    label {
      font-weight: 600;
    }
  }

  &-block {
    display: block;
    width: 100%; // Fixes width issue when the parent is display: flex;

    label {
      display: block;
    }

    // For block checkboxes, we got to align the [x] based on the full height
    input[type='checkbox'] {
      + span::before,
      + span::after {
        top: 50%;
        transform: translateY(-50%);
      }

      + span::after {
        transform: translateY(-50%) rotate(-45deg) scale(0.88);
        margin-top: -1px;
      }
    }
  }
}
