$button-height: 34px;
$button-width: 240px;

$step-height-mobile: 340px;
$step-height-desktop: 360px;

.new-deal-modal {
  margin-left: auto;
  margin-right: auto;

  @include mediaquery-medium {
    width: $breakpoint-tablet;
  }

  .modal-header {
    border-bottom: 1px solid $black10 !important;
    .headline {
      display: inline-block !important;
    }
  }

  .tab-selector {
    padding: 15px;
    button {
      width: 80px;
    }
  }

  .sandbox {
    //my templates | outlaw templates
    background: $black2;
    padding: 10px 0;
    text-align: center;
    .btn-group {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .modal-body {
    margin: 0;
    display: flex;
    flex-direction: column;

    .wrapper.new-deal {
      padding: 20px 0px 20px 0px;
    }

    &.template-no-team-template {
      text-align: center;
      height: 200px;
      padding: 90px 0;
      color: $gray-2;
    }

    &.template-loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 495px;
    }

    .deal-from-template {
      position: relative;
      //each one of these is a row item
      display: flex;
      align-items: center;
      border-bottom: 1px solid $black10;
      padding: 10px 50px 15px 20px;
      background-image: url(/assets/svg/btn-chevron-gray.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 20px) center;

      h5 {
        margin-bottom: 1px;
      }

      &:last-child {
        border-bottom: none;
      }
      &:hover {
        cursor: pointer;
        background-color: $black2;
        .dmp-loader {
          background-color: $black2;
        }

        h5 {
          text-decoration: underline;
        }
      }
      .dmp-loader {
        position: absolute;
        right: 5px;
        top: 20px;
        background-color: white;
        padding: 5px;
      }
    }
  }

  .new-deal-search {
    .rw-combobox .rw-widget-picker {
      grid-template: 1fr/1fr 4.5em;
    }
  }
}
